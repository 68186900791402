export interface AuthStatus {
    ready: boolean;
    authenticated: boolean | 'pending';
}

export class UserPermissions {
    customer: {
        create: boolean;
        remove: boolean;
        edit: boolean;
    } = {
        create: false,
        remove: false,
        edit: false,
    };
    project: {
        create: boolean;
        remove: boolean;
        edit: boolean;
        invite: boolean;
    } = {
        create: false,
        remove: false,
        edit: false,
        invite: false,
    };

    constructor(isAdmin = false) {
        if (!isAdmin) {
            return;
        }
        this.project = {
            create: true,
            remove: true,
            edit: true,
            invite: true,
        };
        this.customer = {
            create: true,
            remove: true,
            edit: true,
        };
    }
}

export type Token = {
    access_token: string;
    expires_in: number;
    expires_at: number | Date;
    id_token: string;
    refresh_token: string;
    token_type: 'Bearer';
};

export type UserInfo = {
    email: string;
    emailVerified: boolean;
    isAdmin: boolean;
    groups: {
        [s: string]: boolean;
    };
    permissions: UserPermissions;
};
