import {HttpClient, HttpResponse} from '../api-services/http-client.service';
import {ChecklistQuestion, ChecklistSection, ChecklistStatus, QuestionOption} from './checklist.model';
import {urlBuilder} from '../../helpers/urlBuilder';

const sectionsWithStatus = async (projectId: string): Promise<HttpResponse<ChecklistSection[]>> => {
    let [res1, sectionErr] = await CheckListService.sections();
    const [res2] = await CheckListService.status(projectId);
    const [res3] = await CheckListService.options();
    const [res4] = await CheckListService.answers(projectId);
    if (res1 && res2 && res3 && res4) {
        const shortlist: ChecklistQuestion[] = [];
        res1 = res1
            .map((el) => {
                el.checklistQuestions = el.checklistQuestions
                    .map((el2) => {
                        if (el2.shortlist) {
                            shortlist.push(el2);
                        }
                        return el2;
                    })
                    .filter((el2) => !el2.shortlist);
                return el;
            })
            .filter((el) => el.checklistQuestions.length);

        res1.push({
            id: 'shortlist',
            displayName: 'Teams Rooms Settings',
            uiOrder: 0,
            propertyName: 'Shortlist',
            checklistQuestions: shortlist,
        });

        res2.outstandingSections.forEach((el) => {
            const index = res1.findIndex((el2) => el2.displayName === el);
            if (index === -1) {
                return;
            }
            res1[index].incomplete = true;
        });

        Object.keys(res3).forEach((el) => {
            // @ts-ignore
            res3[el] = res3[el].map((el) => {
                return {
                    value: el.id,
                    label: el.displayText,
                };
            });
        });

        res1.map((sec) => {
            sec.checklistQuestions = sec.checklistQuestions.map((q) => {
                if (q.responseType === 'select') {
                    q.checklistOptions = res3[q.id];
                }
                q.value = res4[q.id];
                return q;
            });
            return sec;
        });
    }
    return [res1, sectionErr, undefined];
};

const sections = async (): Promise<HttpResponse<ChecklistSection[]>> => {
    return HttpClient.get(urlBuilder('checklist', 'sections'));
};

const status = async (projectId: string): Promise<HttpResponse<ChecklistStatus>> => {
    return HttpClient.get(urlBuilder('project', projectId, 'checklist', 'status'));
};

const questions = async (): Promise<HttpResponse<ChecklistQuestion[]>> => {
    return await HttpClient.get(urlBuilder('checklist', 'questions'));
};

const options = async (): Promise<HttpResponse<{[s: string]: QuestionOption[]}>> => {
    return await HttpClient.get(urlBuilder('checklist', 'options'));
};

const update = async (projectId: string, payload: {[s: string]: string}) => {
    let [res, err] = await HttpClient.post(urlBuilder('project', projectId, 'checklist'), payload);
    if (res) {
        return await answers(projectId);
    }
    return [res, err];
};

const answers = async (projectId: string): Promise<HttpResponse<{[s: string]: string}>> => {
    return await HttpClient.get(urlBuilder('project', projectId, 'checklist'));
};

export const CheckListService = {
    sections,
    sectionsWithStatus,
    questions,
    options,
    update,
    answers,
    status,
};
