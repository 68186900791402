import {useNewPassword} from '../../../hooks/useNewPassword';
import {Button, Group, PasswordInput} from '@mantine/core';
import {useState} from 'react';
import {ErrorListDisplayUL} from './loginCommon';

export const usePasswordForm = (onSubmit: Function, submitButtonTitle: string) => {
    const {password, setPassword, confirm, setConfirm, errors, errorTxt} = useNewPassword();
    const [submitAttempt, setSubmitAttempt] = useState(false);

    const onPasswordInput = (e: any) => setPassword(e.target.value);
    const onConfirmInput = (e: any) => setConfirm(e.target.value);

    const submit = async () => {
        setSubmitAttempt(true);
        if (!errors.valid || !errors.match) return;
        await onSubmit(password);
    };

    const form = (
        <>
            <div>
                <PasswordInput
                    required
                    mb='sm'
                    value={password}
                    onInput={onPasswordInput}
                    label='New Password'
                    error={submitAttempt && !errors.valid}
                />
                {submitAttempt && (
                    <ErrorListDisplayUL>
                        {errorTxt.map((el, i) => (
                            <li key={i}>{el}</li>
                        ))}
                    </ErrorListDisplayUL>
                )}
            </div>

            <div>
                <PasswordInput
                    required
                    mb='sm'
                    value={confirm}
                    onInput={onConfirmInput}
                    label='Confirm Password'
                    error={submitAttempt && password !== confirm}
                />
                {submitAttempt && !errors.match && (
                    <ErrorListDisplayUL>
                        <li>Passwords don't match!</li>
                    </ErrorListDisplayUL>
                )}
            </div>

            <Group grow mt='lg'>
                <Button onClick={submit}>{submitButtonTitle}</Button>
            </Group>
        </>
    );

    return {
        form,
        password,
        setPassword,
        confirm,
        setConfirm,
        errors,
        errorTxt,
    };
};
