import {Alert} from '@mantine/core';
import {usePasswordForm} from './usePasswordForm';
import {CognitoAuthResponse} from '../amplify.actions';
import {Auth2Service} from '../auth2.service';
import {NotificationService} from '../../notification.service';

interface Props {
    user: CognitoAuthResponse;
}

export const PasswordChallenge = (props: Props) => {
    const {user} = props;

    const submit = async (password: string) => {
        try {
            const res = await Auth2Service.completeNewPassword(user, password);
        } catch (e: any) {
            NotificationService.add(e.message, 'error');
        }
    };

    const {form} = usePasswordForm(submit, 'Set New Password');

    if (!user) return <></>;

    return (
        <>
            <Alert color='violet' mb={20} variant='light'>
                <span>For security reasons you are required to change your password.</span>
            </Alert>

            {form}
        </>
    );
};
