import React, {useEffect, useState} from 'react';
import {Draggable} from './Draggable';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider} from 'react-dnd';
import {useSnackbar} from 'notistack';
import {DeviceEntity, DeviceService} from '../../../../services/api-services/device.service';
import {ProjectService} from '../../../../services/api-services/project.service';
import {ProjectFolderEntity, ProjectFolderInterface} from '../../../../services/api-services/project-folders.service';
import {HttpResponse} from '../../../../services/api-services/http-client.service';
import {Button, Group, LoadingOverlay} from '@mantine/core';
import {DrawerForm} from '../../../../components/Drawer/DrawerForm/DrawerForm';
import {FormDefinition, KeyValueObject} from '../../../../components/Drawer/DrawerForm/DynamicForm';

export const DeviceManager4 = (props: any) => {
    const {projectName: _projectName, projectId, customerId} = props;
    const [list, setList] = useState<any>();
    // const [projectId, setProjectId] = useState<string>();
    // const [customerId, setCustomerId] = useState(null);
    const [project, setProject] = useState<any>();
    // const {projectId, customerId} = useParams<any>();
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        // setCustomerId(params.customerId);
        // setProjectId(params.projectId);
        getDevices();
    }, [props.projectId, props.customerId]);

    const getDevices = async () => {
        if (!projectId) {
            return;
        }
        setLoading(true);
        const [res, err] = await ProjectService.folder.get(projectId);
        setLoading(false);

        const mapThem = (devices: any, folders: any) => {
            const d = devices.map((el: any) => {
                return {
                    ...el,
                    config: {
                        type: 'device',
                        accept: [],
                    },
                };
            });
            const f = folders.map((el: ProjectFolderEntity) => {
                return {
                    ...el,
                    items: mapThem(el.devices, el.folders),
                    config: {
                        type: 'folder',
                        accept: ['device', 'folder'],
                    },
                };
            });
            return [...f, ...d];
        };

        setList({
            id: '0',
            name: _projectName || 'Project',
            items: mapThem(res.devices, res.folders),
            config: {
                type: 'folder',
                accept: ['device', 'folder'],
            },
        });

        return {res, err};
    };

    const handleDrop = async (source: any, target: any) => {
        console.log({source, target});
        const {type} = source.config;
        const {id} = source;
        const {id: targetId} = target;

        if (id === targetId || target.config.type === 'device' || !projectId) {
            return;
        }

        if (type === 'device') {
            const parentFolderId = targetId === '0' ? '' : targetId;
            const [deviceDetails] = await DeviceService.get(id);
            if (deviceDetails.parentFolderId === targetId) {
                return;
            }
            await DeviceService.update({
                ...deviceDetails,
                parentFolderId,
            } as DeviceEntity);
            getDevices();
        } else if (type === 'folder') {
            console.log(targetId.id);
            const parentFolderId = targetId === '0' ? '' : targetId;
            const payload: ProjectFolderInterface = {
                id,
                name: source.name,
                projectId,
                parentFolderId,
            };
            await ProjectService.folder.update(payload);
            getDevices();
        }
    };

    const onAddDevice = async (result: any): Promise<HttpResponse<any>> => {
        const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
        if (result.macAddress && !result.macAddress.match(macRegex)) {
            enqueueSnackbar('Mac address should follow pattern: aa:aa:aa:aa:aa:aa', {variant: 'error'});
            // @ts-ignore
            return [null, null];
        }
        return await DeviceService.create(result);
    };

    const onAddFolder = async (result: ProjectFolderEntity) => {
        return await ProjectService.folder.create(result.projectId, result.name, '');
    };

    const newFolderHidden = ['projectId', 'parentFolderId'];
    const newFolderForm = {
        name: {
            title: 'Folder Name',
            validation: {
                required: true,
            },
        },
        projectId: {
            title: 'projectId',
            value: projectId,
        },
    };

    const newDeviceHidden = ['projectId', 'customerId', 'modelId', 'adminPassword'];
    const newDeviceForm: FormDefinition = {
        name: {
            title: 'Name',
            validation: {
                required: true,
            },
        },
        projectId: {
            title: '',
            value: projectId,
        },
        serialNumber: {
            title: 'Serial Number',
        },
        macAddress: {
            title: 'Mac Address',
        },
        teamsUsername: {
            title: 'Teams Username',
        },
        teamsPassword: {
            title: 'Teams Password',
            type: 'password',
        },
        // skypeSignInAddress: {
        //     title: 'Skype sign-in address',
        // },
        // exchangeAddress: {
        //     title: 'Exchange address',
        // },
    };

    const [loading, setLoading] = useState(false);

    const submitButton1 = (
        <Button size='xs' variant='outline'>
            CREATE DEVICE
        </Button>
    );
    const handleCreateDevice = async (result: KeyValueObject) => {
        return await onAddDevice(result);
    };

    const submitButton2 = (
        <Button size='xs' variant='outline'>
            CREATE FOLDER
        </Button>
    );
    const handleSubmit = async (result: KeyValueObject) => await onAddFolder(result as any);

    return (
        <div style={{position: 'relative', height: '100%'}}>
            <LoadingOverlay zIndex={199} visible={loading} />
            <div>
                <div style={{display: 'flex', marginBottom: '20px'}}>
                    <Group>
                        <DrawerForm
                            formData={newDeviceForm}
                            hiddenFields={newDeviceHidden}
                            title='Create a device'
                            openButton={submitButton1}
                            submit={handleCreateDevice}
                            success={getDevices}
                            submitButtonTitle='Create'
                        />
                        <DrawerForm
                            formData={newFolderForm}
                            hiddenFields={newFolderHidden}
                            title='Create a folder'
                            openButton={submitButton2}
                            submit={handleSubmit}
                            success={getDevices}
                            submitButtonTitle='Create'
                        />
                    </Group>
                </div>
                <div
                    style={{
                        paddingLeft: '10px',
                        backgroundImage: 'url("/static/media/bg.8d0f6f32.svg")',
                    }}
                >
                    <DndProvider backend={HTML5Backend}>
                        {list && <Draggable item={list} handleDrop={handleDrop} reload={getDevices} />}
                    </DndProvider>
                </div>
            </div>
        </div>
    );
};
