const colors = {
    primaryColor: '#280b56',
    drawerColor: '#f5f5f5',
    or: '#fff',
    boxShadow: '0px 1px 2px 0px rgb(60 64 67 / 30%), 0px 1px 3px 1px rgb(60 64 67 / 15%)',
};

const dimensions = {
    drawerWidth: 240,
    drawerPadding: 20,
    pagePaddingTop: 50,
    pagePaddingBottom: 150,
    appBarHeight: 40,
};

const calculatedDimensions = {
    drawer: {
        width: 240,
        padding: 20,
    },
    appBar: {
        height: 40,
    },
    page: {
        pagePadding: 50,
        pageWastedTop: dimensions.pagePaddingTop + dimensions.appBarHeight,
        pageWasted: dimensions.pagePaddingTop + dimensions.appBarHeight + dimensions.pagePaddingBottom,
    },
};

const recursePx = (input: any) => {
    if (typeof input === 'number') {
        return input + 'px';
    } else {
        const copied = {...input};
        Object.keys(copied).forEach((key) => {
            copied[key] = recursePx(input[key]);
        });
        return copied;
    }
};

const processedDimensions = recursePx({...calculatedDimensions});

export const themeConfig = {
    dimensions: calculatedDimensions,
    colors,
    processedDimensions: processedDimensions,
};
