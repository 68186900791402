import {CSSProperties, FC, ReactNode} from 'react';
import {useDrag, useDrop} from 'react-dnd';
import {ItemActions} from './ItemActions';
import {MaterialIcon} from '../../../../components/MaterialIcon';
import TeamsLogo from './../../../../../assets/teams.logo.svg';

export const ItemTypes = {
    DEVICE: 'device',
    FOLDER: 'folder',
};

function getStyle(): CSSProperties {
    return {
        fontSize: '1rem',
    };
}

function styleChildren(): CSSProperties {
    return {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '40px',
    };
}

export interface DustbinProps {
    greedy?: boolean;
    children?: ReactNode;
    item: any;
    handleDrop: (source: any, target: any) => void | any;
    reload: () => void;
}

export const Draggable: FC<DustbinProps> = (props: DustbinProps) => {
    const {item, handleDrop, reload} = props;

    const [{isDragging}, drag] = useDrag(() => ({
        type: ItemTypes.DEVICE,
        collect: (monitor) => {
            return {isDragging: monitor.isDragging()};
        },
        end: (draggedItem, monitor) => {
            const dropResult: any = monitor.getDropResult();
            if (!dropResult?.target) {
                return;
            }
            const s = props.item;
            const t = dropResult.target;
            handleDrop(s, t);
        },
    }));

    const [{isOver, isOverCurrent}, drop] = useDrop(
        () => ({
            accept: ItemTypes.DEVICE,
            drop(target: unknown, monitor: any) {
                const didDrop = monitor.didDrop();
                if (didDrop) {
                    return;
                }
                return {target: item};
            },
            collect: (monitor: any) => ({
                isOver: monitor.isOver(),
                isOverCurrent: monitor.isOver({shallow: true}),
            }),
        }),
        []
    );

    const display = (
        <span style={{height: '30px', display: 'flex', alignItems: 'center', backgroundColor: 'transparent'}}>
            <span style={{marginRight: '5px', display: 'flex'}}>
                {item.config.type === 'device' ? (
                    // <MaterialIcon name="video_settings" />
                    // <MaterialIcon variant='outlined' name='smart_display' />
                    <img src={TeamsLogo} alt='teams' width='45' style={{margin: -10}} />
                ) : isOverCurrent ? (
                    <MaterialIcon variant='outlined' name='drive_file_move' />
                ) : (
                    <MaterialIcon variant='outlined' name='folder' />
                )}
            </span>
            <span>{item.name}</span>
        </span>
    );

    return (
        <div className='hey'>
            {isDragging ? (
                <span>{display}</span>
            ) : (
                <div ref={drop} style={getStyle()}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div ref={drag}>{display}</div>
                        {item.id !== '0' && <ItemActions item={item} onSuccess={reload} />}
                    </div>
                    <div style={styleChildren()}>
                        {item.items?.map((el: any) => (
                            <Draggable handleDrop={handleDrop} key={el.id} item={el} reload={reload} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};
