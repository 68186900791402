import React from 'react';
import {AppLayout} from './components/AppLayout/AppLayout';
import {Logout} from './services/auth/Logout';
import {SnackbarProvider} from 'notistack';
import {Navigate, Route, Routes} from 'react-router-dom';
import {CustomersList} from './pages/CustomerList';
import {ProjectList} from './pages/ProjectList';
import {ProjectView} from './pages/ProjectView/ProjectView';
import {AppManager} from './components/AppManager';
import {MantineProvider, MantineThemeOverride} from '@mantine/core';
import {NotificationsDisplay} from './components/NotificationsDisplay';
import {Login} from './services/auth/Login';
import {Auth2Service} from './services/auth/auth2.service';
import {AmplifyActions} from './services/auth/amplify.actions';

function App() {
    Auth2Service.user.subscribe((res) => console.log(res));
    AmplifyActions.listen();

    const theme: MantineThemeOverride = {
        shadows: {
            md: '0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)',
        },
    };

    return (
        <SnackbarProvider maxSnack={4}>
            <MantineProvider theme={theme}>
                <NotificationsDisplay />
                <AppManager>
                    <AppLayout>
                        <Routes>
                            <Route path='/login' element={<Login />} />
                            <Route path='/logout' element={<Logout />} />
                            <Route path='/customer' element={<CustomersList />} />
                            <Route path='/customer/:customerId' element={<ProjectList />} />
                            <Route path='/project/:projectId' element={<ProjectView />} />
                            <Route path='*' element={<Navigate to='/customer' />} />
                        </Routes>
                    </AppLayout>
                </AppManager>
            </MantineProvider>
        </SnackbarProvider>
    );
}

export default App;
