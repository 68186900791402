import {Paper} from '@mantine/core';
import Logo from '../../../assets/kinly-alt.logo.svg';
import {useEffect, useState} from 'react';
import {Auth2Service} from './auth2.service';
import {Subscription} from 'rxjs';
import {useNavigate} from 'react-router-dom';
import {useNewPassword} from '../../hooks/useNewPassword';
import {LoginForm} from './LoginForm/LoginForm';

export const Login = () => {
    const navigate = useNavigate();
    const [ready, setReady] = useState(false);
    let subscription: Subscription;

    useNewPassword();

    useEffect(() => {
        subscription = Auth2Service.status.subscribe((res) => {
            console.log(res);
            if (res.ready && res.authenticated) {
                navigate(Auth2Service.blockedPath.get);
                Auth2Service.blockedPath.clear();
            } else {
                setReady(true);
            }
        });
        return () => subscription.unsubscribe();
    }, []);

    const g = '#f6f9fc';

    return (
        <div
            style={{
                backgroundColor: '#040c1f',
                display: 'flex',
                height: '100vh',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Paper
                shadow='md'
                style={{
                    width: '100%',
                    maxWidth: '700px',
                    height: '100%',
                    maxHeight: '500px',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                sx={(theme) => ({
                    backgroundColor: theme.colors.gray[0],
                })}
            >
                <Paper
                    padding='md'
                    style={{
                        backgroundColor: '#fff',
                        height: '100%',
                        width: '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexShrink: 0,
                        padding: 5,
                        border: '1px solid #d7d7d7',
                        borderRadius: 0,
                    }}
                >
                    <img src={Logo} alt='Kinly' />

                    <Paper
                        mt='lg'
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <div style={{fontFamily: 'Ubuntu'}}>Microsoft Teams Rooms</div>
                        <div style={{fontFamily: 'Ubuntu'}}>Provisioning Portal</div>
                    </Paper>
                </Paper>
                <Paper
                    padding='md'
                    style={{
                        backgroundColor: g,
                        width: '50%',
                        // display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexShrink: 0,
                        padding: '20px 5px',
                        height: '100%',
                        maxHeight: '100%',
                        overflowY: 'auto',
                    }}
                >
                    <div
                        style={{
                            display: 'block',
                            position: 'relative',
                            // marginTop: 30,
                            boxSizing: 'border-box',
                            padding: 20,
                            width: '100%',
                        }}
                    >
                        <LoginForm />
                    </div>
                </Paper>
            </Paper>
        </div>
    );
};
