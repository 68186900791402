export const awsConf = {
    // Region where Amazon Cognito project was created
    aws_cognito_region: process.env.REACT_APP_REGION as string,

    // Amazon Cognito User Pool ID
    aws_user_pools_id: process.env.REACT_APP_POOL_ID as string,

    // Amazon Cognito App Client ID (App client secret needs to be disabled)
    aws_user_pools_web_client_id: process.env.REACT_APP_CLIENT_ID as string,

    oauth: {
        domain: (process.env.REACT_APP_DOMAIN as string).substring(8),
        scope: ['email', 'openid'],
        redirectSignIn: window.location.origin + '/login',
        redirectSignOut: window.location.origin + '/login',
        clientId: process.env.REACT_APP_CLIENT_ID as string,
        responseType: 'code',
    },
};
