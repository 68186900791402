import {Dropzone, IMAGE_MIME_TYPE} from '@mantine/dropzone';
import React, {useEffect, useState} from 'react';
import {Button, Group, LoadingOverlay, Text} from '@mantine/core';
import {MaterialIcon} from '../../../components/MaterialIcon';
import {WithTitle} from '../ProjectView';
import {ProjectService} from '../../../services/api-services/project.service';

export const ImageUpload = (props: {projectId: string | undefined}) => {
    const {projectId} = props;
    const [loading, setLoading] = useState(true);
    const [img, setImg] = useState<string | undefined>();
    const inputRef: any = React.useRef(null);

    useEffect(() => {
        getImage();
    }, []);

    if (!projectId) {
        return <></>;
    }

    function ImageUploadIcon({status, ...props}: any) {
        if (status.accepted) {
            return <MaterialIcon name='upload' {...props} />;
        }

        if (status.rejected) {
            return <MaterialIcon name='cross' {...props} />;
        }

        return <MaterialIcon name='image' {...props} />;
    }

    const title = (
        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <MaterialIcon styles={{color: '#ACACAC'}} name='panorama' />
            <div style={{color: '#5C5C5C', marginLeft: 6}}>Teams Background Image</div>
        </div>
    );

    const upload = async (files: any) => {
        setLoading(true);
        const [res] = await ProjectService.image.upload(projectId, files[0]);
        if (res.data) {
            setImg('data:image/png;base64, ' + res.data);
        }
        setLoading(false);
    };

    const getImage = async () => {
        setLoading(true);
        const [res] = await ProjectService.image.getImage(projectId);
        if (res.data) {
            setImg('data:image/png;base64, ' + res.data);
        }
        setLoading(false);
    };

    const remove = async () => {
        setLoading(true);
        await ProjectService.image.remove(projectId);
        setImg(undefined);
        setLoading(false);
    };

    const browse = () => {
        inputRef?.current?.click();
    };

    return (
        <WithTitle title={title}>
            <LoadingOverlay zIndex={199} visible={loading} />
            {img && (
                <Group mb={10}>
                    <input
                        style={{display: 'none'}}
                        ref={inputRef}
                        type='file'
                        onInput={(e: any) => upload(e.target?.files)}
                        accept={IMAGE_MIME_TYPE.join(',')}
                    />
                    <Button variant='subtle' onClick={remove}>
                        Delete
                    </Button>
                    <Button variant='subtle' onClick={browse}>
                        Replace
                    </Button>
                </Group>
            )}

            {img && <img src={img} />}

            {!img && (
                <Dropzone
                    onDrop={(files) => upload(files)}
                    onReject={(files) => console.log('rejected files', files)}
                    // maxSize={3 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE}
                    style={{height: 200}}
                >
                    {(status) => (
                        <>
                            <Group position='center' spacing='xl' style={{minHeight: 170, pointerEvents: 'none'}}>
                                {/*<MaterialIcon styles={{fontSize: 30}} name='image'/>*/}

                                <ImageUploadIcon
                                    status={status}
                                    styles={{
                                        color: 'gray',
                                        fontSize: 45,
                                    }}
                                />

                                <div>
                                    <Text size='xl' inline>
                                        Drag images here or click to select files
                                    </Text>
                                    <Text size='sm' color='dimmed' inline mt={7}>
                                        Images with certain dimensions are allowed.
                                    </Text>
                                </div>
                            </Group>
                        </>
                    )}
                </Dropzone>
            )}
        </WithTitle>
    );
};
