import Amplify, {Auth, Hub} from 'aws-amplify';
import {awsConf} from './amplify.config';
import {BehaviorSubject} from 'rxjs';
import {UserInfo, UserPermissions} from './auth.models';
import {CognitoUser} from 'amazon-cognito-identity-js';

export interface CognitoAuthResponse extends CognitoUser {
    challengeName?: 'NEW_PASSWORD_REQUIRED';
}

Amplify.configure(awsConf);

const cognitoMsgDic: {[s: string]: string} = {
    signIn: 'user signed in',
    signUp: 'user signed up',
    signOut: 'user signed out',
    signIn_failure: 'user sign in failed',
    tokenRefresh: 'token refresh succeeded',
    tokenRefresh_failure: 'token refresh failed',
    configured: 'the Auth module is configured',
};

export type CognitoAuthStatus = {event: string; message: string};
const cognitoAuthStatus = new BehaviorSubject<CognitoAuthStatus>({event: 'pending', message: 'Waiting for events'});

const listener = (data: any) => {
    const interpretedMsg: string = cognitoMsgDic[data?.payload?.event] || data?.event?.payload;
    cognitoAuthStatus.next({event: data.payload.event, message: interpretedMsg});
};

const listen = () => Hub.listen('auth', listener);

const getCognitoSessionUser = async (): Promise<UserInfo | false> => {
    try {
        const session = await Auth.currentSession();
        const idPayload = session.getIdToken().payload;
        return (
            idPayload && {
                email: idPayload.email,
                emailVerified: idPayload.email_verified,
                groups: idPayload['cognito:groups'],
                isAdmin: idPayload['cognito:groups'].includes('smpd-admin'),
                permissions: new UserPermissions(idPayload['cognito:groups'].includes('smpd-admin')),
            }
        );
    } catch (e) {
        return false;
    }
};

const signIn = async (username: string, password: string): Promise<CognitoAuthResponse> => {
    return await Auth.signIn(username, password);
};

const federatedSignIn = async () => {
    return await Auth.federatedSignIn({customProvider: 'kinly.com'});
};

const signOut = async () => {
    return await Auth.signOut();
};

const getToken = async () => {
    return (await Auth.currentSession()).getAccessToken().getJwtToken();
};

const completeNewPassword = async (user: CognitoUser, newPassword: string) => {
    return Auth.completeNewPassword(user, newPassword);
};

const forgetPassword = async (username: string) => await Auth.forgotPassword(username);

const forgetPasswordSubmit = (username: string, code: string, password: string) => {
    return Auth.forgotPasswordSubmit(username, code, password);
};

export const AmplifyActions = {
    signIn,
    federatedSignIn,
    signOut,
    getToken,
    getCognitoSessionUser,
    cognitoAuthStatus,
    listen,
    completeNewPassword,
    forgetPassword,
    forgetPasswordSubmit,
};
