import {useState} from 'react';
import {DeviceInterface, DeviceService} from '../../../../services/api-services/device.service';
import {HttpResponse} from '../../../../services/api-services/http-client.service';
import {ProjectFolderInterface} from '../../../../services/api-services/project-folders.service';
import {ProjectService} from '../../../../services/api-services/project.service';
import {Group, Menu, Modal, Button, Alert, LoadingOverlay} from '@mantine/core';
import {Pencil1Icon, TrashIcon} from '@modulz/radix-icons';
import {FormDefinition} from '../../../../components/Drawer/DrawerForm/DynamicForm';
import {DrawerForm} from '../../../../components/Drawer/DrawerForm/DrawerForm';

export const ItemActions = (props: any) => {
    const {item, onSuccess} = props;
    const [editMode, setEditMode] = useState<boolean>(false);
    const [_showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false);
    const hiddenFields = ['id', 'folderId', 'projectId', 'parentFolderId', 'modelId', 'created', 'modified'];
    const [loading, setLoading] = useState(false);

    const formData = (): FormDefinition => {
        if (item.config.type === 'folder') {
            return {
                id: {value: item.id},
                name: {title: 'Name', value: item.name},
            };
        }
        if (item.config.type === 'device') {
            return {
                id: {
                    value: item.id,
                },
                name: {
                    title: 'Name',
                    value: item.name,
                    validation: {
                        required: true,
                    },
                },
                serialNumber: {
                    value: item.serialNumber,
                    title: 'Serial Number',
                },
                macAddress: {
                    value: item.macAddress,
                    title: 'Mac Address',
                },
                teamsUsername: {
                    value: item.teamsUsername,
                    title: 'Teams Username',
                },
                teamsPassword: {
                    value: item.teamsPassword,
                    title: 'Teams Password',
                    type: 'password',
                },
                // skypeSignInAddress: {
                //     value: item.skypeSignInAddress,
                //     title: 'Skype sign-in address',
                // },
                // exchangeAddress: {
                //     value: item.exchangeAddress,
                //     title: 'Exchange address',
                // },
            };
        }
        return {};
    };

    const handleUpdate = async (result: any): Promise<HttpResponse<DeviceInterface | ProjectFolderInterface>> => {
        if (item.config.type === 'device') {
            return await DeviceService.update(result);
        } else {
            return await ProjectService.folder.update(result);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        switch (item.config.type) {
            case 'device':
                await DeviceService.remove(item.id);
                break;
            case 'folder':
                await ProjectService.folder.remove(item.id);
                break;
        }
        setLoading(false);
        setShowDeleteWarning(false);
        onSuccess();
    };

    const dialog = (
        <Modal
            opened={_showDeleteWarning}
            onClose={() => setShowDeleteWarning(false)}
            title='Are you sure?'
            zIndex={410}
            centered
            closeOnClickOutside={!loading}
        >
            <LoadingOverlay zIndex={199} visible={loading} />
            <div color='red' style={{marginBottom: 25}}>
                Deleting a resource is irreversible.
            </div>
            <Group>
                <Button color='green' onClick={() => setShowDeleteWarning(false)}>
                    Cancel
                </Button>
                <Button color='red' variant='light' onClick={handleDelete} autoFocus>
                    Delete anyway
                </Button>
            </Group>
        </Modal>
    );

    return (
        <>
            <DrawerForm
                formData={formData()}
                title={'Edit ' + item.name}
                hiddenFields={hiddenFields}
                submit={handleUpdate}
                success={onSuccess}
                openState={{isOpen: editMode, setIsOpen: setEditMode}}
            />
            {dialog}
            <div style={{display: 'flex', alignItems: 'center', marginLeft: '20px'}}>
                <Menu position='bottom'>
                    <Menu.Item onClick={() => setEditMode(true)} icon={<Pencil1Icon />}>
                        Edit
                    </Menu.Item>
                    <Menu.Item onClick={() => setShowDeleteWarning(true)} color='red' icon={<TrashIcon />}>
                        Delete
                    </Menu.Item>
                </Menu>
            </div>
        </>
    );
};
