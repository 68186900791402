import {useState} from 'react';
import {Button, Group, LoadingOverlay, Modal} from '@mantine/core';

export const DeleteDialog = (props: {name?: string; submit: any; success?: any}) => {
    const [opened, setOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const remove = async () => {
        setLoading(true);
        await props.submit();
        if (props.success) {
            props.success();
        }
        setLoading(true);
        toggle();
    };

    const toggle = () => setOpened((s) => !s);

    return (
        <>
            <Modal
                opened={opened}
                centered
                onClose={toggle}
                closeOnClickOutside={!loading}
                title={`Remove ` + props.name}
            >
                <LoadingOverlay zIndex={199} visible={loading} />
                <div color='red' style={{marginBottom: 25}}>
                    Deleting a resource is irreversible.
                </div>
                <Group>
                    <Button color='green' onClick={toggle}>
                        Cancel
                    </Button>
                    <Button color='red' variant='light' onClick={remove} autoFocus>
                        Delete anyway
                    </Button>
                </Group>
            </Modal>
            <Button size='xs' variant='outline' color='red' onClick={() => setOpened(true)}>
                Remove
            </Button>
        </>
    );
};
