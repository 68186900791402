import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {Api} from './API';
import {AmplifyActions} from '../auth/amplify.actions';
import {NotificationService} from '../notification.service';

const httpErrorCodes = {
    400: '400 Bad Request: verify that you have provided all the necessary information.',
    401: '401 Unauthorized.',
    403: '403 Forbidden.',
    409: '409 Conflict: some fields need to be unique.',
    418: "418 I'm a teapot.",
    500: '500 Server Error: try again in a few minutes.',
    503: '503 Service Unavailable: try again in a few minutes.',
};

export type HttpResponse<T> = [
    res: T,
    err: {result: AxiosError; response: AxiosResponse | undefined; data: any} | undefined,
    status: number | undefined
];

export const client: AxiosInstance = axios.create({
    baseURL: Api.base,
});

client.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
        const token = await AmplifyActions.getToken();
        if (config.headers && token && !config.headers.noAuth) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        const logURL = ' 📜 ' + config.method?.toUpperCase() + ' ' + config.url;
        console.log(logURL);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const handleResponse = (res: AxiosResponse) => [res?.data, null, res?.status];

const handleErrorResponse = async (err: AxiosError) => {
    const reqConfig: any = {...err.config};

    let errMsg;
    if (err.response?.status && [400, 401, 403, 409, 418].includes(err.response.status)) {
        // @ts-ignore
        errMsg = httpErrorCodes[err.response.status];
    } else {
        errMsg = err.response?.status.toString();
        errMsg = errMsg + ' ' + err.config.method?.toUpperCase();
        errMsg = errMsg + ' ' + err.config.url;
    }

    if (
        err.response?.status &&
        (err.response.status !== 401 || reqConfig.retry) &&
        err?.config?.baseURL &&
        !err?.config?.baseURL.includes('amazoncognito.com')
    ) {
        NotificationService.add(errMsg, err.response?.status === 418 ? 'warning' : 'error');
    }

    // if (!reqConfig.retry && err.response?.status === 401) {
    //     reqConfig['retry'] = true;
    //     await UserService.refresh();
    //     return client(reqConfig);
    // }

    // window.location.replace(window.location.origin + '/logout');
    let processed;
    if (axios.isAxiosError(err)) {
        processed = {result: err, response: err?.response, data: err?.response?.data};
    }
    return [null, processed, processed?.response?.status];
};

client.interceptors.response.use(
    (res) => handleResponse(res),
    (err) => handleErrorResponse(err)
);

const get = async <T>(url: string, config: AxiosRequestConfig | undefined = undefined): Promise<HttpResponse<T>> => {
    return await client.get(url, config);
};

const post = async <T>(
    url: string,
    data: any = null,
    config: AxiosRequestConfig | undefined = undefined
): Promise<HttpResponse<T>> => {
    return await client.post(url, data, config);
};

const put = async <T>(
    url: string,
    data: any = null,
    config: AxiosRequestConfig | undefined = undefined
): Promise<HttpResponse<T>> => {
    return await client.put(url, data, config);
};

const del = async <T>(url: string, config: AxiosRequestConfig | undefined = undefined): Promise<HttpResponse<T>> => {
    return await client.delete(url, config);
};

export const HttpClient = {get, post, put, delete: del};
