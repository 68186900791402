import {useMemo, useState} from 'react';

const txt = {
    length: 'Should contain at least 8 characters.',
    containsLowerCase: 'Should contain at least 1 lowercase letter.',
    containsUpperCase: 'Should contain at least 1 uppercase letter.',
    containsSpecial: 'Should contain at least 1 special character.',
    containsDigits: 'Should contain at least 1 number.',
};

export const passwordCriteriaCheck = (text: string, confirm: string) => {
    const length = text.length >= 8;
    const containsDigits = !!text.match(/\d/)?.length;
    const containsLowerCase = !!text.match(/[a-z]/)?.length;
    const containsUpperCase = !!text.match(/[A-Z]/)?.length;
    const specialChar = /[\^$*.[\]{}()?\-"!@#%&\\/,<>':;|_~`+=]/;
    const containsSpecial = !!text.match(specialChar)?.length;

    return {
        valid: length && containsDigits && containsLowerCase && containsUpperCase && containsSpecial,
        length,
        containsDigits,
        containsLowerCase,
        containsUpperCase,
        containsSpecial,
    };
};

export const useNewPassword = () => {
    const [password, setPassword] = useState<string>('');
    const [confirm, setConfirm] = useState<string>('');

    const errors = useMemo(() => passwordCriteriaCheck(password, confirm), [password]);
    const match = useMemo(() => password === confirm, [password, confirm]);

    const errorTxt: string[] = useMemo(() => {
        return Object.keys(errors).map((key) => {
            // @ts-ignore
            return !errors[key] ? txt[key] : null;
        });
    }, [errors]);

    return {
        password,
        setPassword,
        confirm,
        setConfirm,
        errors: {
            ...errors,
            match,
        },
        errorTxt,
    };
};
