import {Grid, Loader, Skeleton} from '@mantine/core';
import {BreadcrumbItem, BreadCrumbs} from './BreadCrumbs';

type Props = {
    title?: string;
    breadcrumb?: BreadcrumbItem[];
};

export const Hero = (props: Props) => {
    const container = {
        backgroundColor: 'rgb(54 53 140)',
        backgroundSize: '1000px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top lef',
        height: '200px',
        margin: '-40px -50px -85px',
        padding: '40px 65px 0',
    };

    const innerStyles = {
        maxWidth: 1320,
        margin: 'auto',
    };

    const title = {
        color: '#fff',
        fontSize: '4rem',
        fontFamily: '"Ubuntu", sans-serif',
        fontWeight: 400,
    };

    return (
        <div style={container}>
            <div style={innerStyles}>
                {props.title ? (
                    <div style={title}>{props.title}</div>
                ) : (
                    <div style={title}>
                        <Loader variant='dots' />
                    </div>
                )}
                {props.breadcrumb && <BreadCrumbs items={props.breadcrumb} />}
            </div>
        </div>
    );
};
