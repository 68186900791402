import {createContext, useEffect, useState} from 'react';
import {CheckListService} from '../../services/checklist/checklist.service';
import {ChecklistSection} from '../../services/checklist/checklist.model';

export interface ProjectViewContextInterface {
    loading: boolean;
    checklist: {
        sections: ChecklistSection[];
        shortlist: ChecklistSection[];
    };
    getChecklist: () => void | any;
}

export const ProjectViewContext = createContext<ProjectViewContextInterface>({
    loading: true,
    checklist: {
        sections: [],
        shortlist: [],
    },
    getChecklist: () => {},
});

export const ProjectViewContextProvider = (props: {projectId: string | undefined; children: any}) => {
    const [sections, setSections] = useState<ChecklistSection[]>([]);
    const [loading, setLoading] = useState(true);

    const {projectId} = props;

    useEffect(() => {
        getChecklist();
    }, []);

    const getChecklist = async () => {
        if (!projectId) {
            return;
        }
        setLoading(true);
        const [res1] = await CheckListService.sectionsWithStatus(projectId);
        setLoading(false);
        setSections(res1);
    };

    return (
        <>
            <ProjectViewContext.Provider
                value={{
                    loading,
                    checklist: {
                        sections: sections
                            .filter((el) => el.checklistQuestions.length)
                            .filter((el) => el.id !== 'shortlist'),
                        shortlist: sections.filter((el) => el.id === 'shortlist'),
                    },
                    getChecklist,
                }}
            >
                {props.children}
            </ProjectViewContext.Provider>
        </>
    );
};
