import {useEffect, useState} from 'react';
import {Button, Group, LoadingOverlay, PasswordInput, TextInput} from '@mantine/core';
import {useEmailField} from '../../../hooks/useEmailField';
import {ErrorListDisplayUL, OtherOption, Title} from './loginCommon';
import {Auth2Service} from '../auth2.service';
import {PasswordForgetSubmit} from './PasswordForget';
import {NotificationService} from '../../notification.service';
import {PasswordChallenge} from './PasswordChallenge';
import {CognitoAuthResponse} from '../amplify.actions';

export const LoginForm = () => {
    const [step, setStep] = useState<'email' | 'password' | 'forget' | 'NEW_PASSWORD_REQUIRED'>('email');
    const [password, setPassword] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [cognitoUser, setCognitoUser] = useState<CognitoAuthResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loginError, setLoginError] = useState<boolean>(false);

    const {email, setEmail, valid, kinlyEmail} = useEmailField();

    useEffect(() => {
        setLoginError(false);
        setShowError(false);
    }, [step, password, email]);

    const onEmailInput = (e: any) => {
        setEmail(e.target.value);
        if (step === 'password') {
            setStep('email');
            setPassword('');
        }
    };
    const onPasswordInput = (e: any) => setPassword(e.target.value);

    const next = async () => {
        setShowError(true);
        if (!valid) return;
        if (kinlyEmail) {
            setLoading(true);
            await Auth2Service.federatedLogin();
            return;
        }
        setShowError(false);
        setStep('password');
    };

    const login = async () => {
        setShowError(true);
        if (!valid) return;
        if (!(password?.length >= 8)) return;
        try {
            setLoading(true);
            setLoginError(false);
            const res = await Auth2Service.login(email, password);
            setCognitoUser(res);
            if (res.challengeName) {
                setStep(res.challengeName);
            }
        } catch (e: any) {
            NotificationService.add(e.message, 'error');
            setLoginError(true);
        }
        setLoading(false);
    };

    const keyPress = (e: any) => e.keyCode === 13 && nextAction();

    const nextAction = () => {
        step === 'email' ? next() : login();
    };

    if (step === 'forget') {
        return (
            <>
                <PasswordForgetSubmit />
                <OtherOption onClick={() => setStep('email')}>Cancel</OtherOption>
            </>
        );
    }

    if (step === 'NEW_PASSWORD_REQUIRED') {
        if (!cognitoUser) return <></>;
        return (
            <>
                <PasswordChallenge user={cognitoUser} />
                <OtherOption onClick={() => setStep('email')}>Cancel</OtherOption>
            </>
        );
    }

    return (
        <>
            <LoadingOverlay visible={loading} />
            <Title>Login</Title>
            <TextInput
                error={loginError || (showError && !valid)}
                required
                mb='md'
                value={email}
                onInput={onEmailInput}
                label='Email'
                onKeyDown={keyPress}
                type='email'
            />
            {showError && !valid && (
                <ErrorListDisplayUL>
                    <li>Email address is invalid.</li>
                </ErrorListDisplayUL>
            )}
            {step === 'password' && (
                <>
                    <PasswordInput
                        required
                        mb='md'
                        value={password}
                        onInput={onPasswordInput}
                        label='Password'
                        error={loginError || (showError && password.length < 8)}
                        onKeyDown={keyPress}
                    />
                    {showError && (
                        <ErrorListDisplayUL>
                            {!password && <li>Password is required.</li>}
                            {password.length < 8 && <li>Password should be at least 8 characters.</li>}
                        </ErrorListDisplayUL>
                    )}
                </>
            )}

            <Group grow mt='lg'>
                <Button onClick={nextAction}>{step === 'email' ? 'Next' : 'Login'}</Button>
            </Group>

            {step === 'password' && <OtherOption onClick={() => setStep('forget')}>Forgot password?</OtherOption>}
        </>
    );
};
