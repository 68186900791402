import React from 'react';
import styled from 'styled-components/macro';
import {AppHeader} from './AppHeader';
import {useLocation} from 'react-router-dom';

const settings = {
    drawerWidth: 240,
    drawerPadding: 20,
    pagePadding: 50,
    appBarHeight: 40,
    primaryColor: '#280b56',
    drawerColor: '#f5f5f5',
    or: '#f3f6f8',
};

const Main = styled.div<any>`
    padding-top: ${(props) => props.padding.top};
    padding-right: ${(props) => props.padding.right};
    padding-bottom: ${(props) => props.padding.bottom};
    padding-left: ${(props) => props.padding.left};
    // height: calc(100vh);
    overflow: auto;
`;

export const AppLayout = (props: any) => {
    const {pathname} = useLocation();
    const isLoginPage = pathname.includes('/login') || pathname.includes('/logout');

    const paddings = {
        left: isLoginPage ? 0 : `${settings.pagePadding}px`,
        right: isLoginPage ? 0 : `${settings.pagePadding}px`,
        top: isLoginPage ? 0 : `${settings.pagePadding + 60}px`,
        bottom: isLoginPage ? 0 : `30px`,
    };

    return (
        <>
            <Main padding={paddings}>{props.children}</Main>
            {!isLoginPage && <AppHeader />}
        </>
    );
};
