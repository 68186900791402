import React, {useEffect, useMemo, useState} from 'react';
import {sleep} from '../helpers/sleep';
import {useLocation, useNavigate} from 'react-router-dom';
import {Auth2Service} from '../services/auth/auth2.service';
import {FullScreenLoader} from './FullScreenLoader';

export const AppManager = (props: any) => {
    const [opacity, setOpacity] = useState(1);
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const authPath: {[s: string]: string} = {
        login: '/login',
        logout: '/logout',
    };

    useEffect(() => console.log('⏩ NAV ' + pathname), [pathname]);

    const isAuthPath = useMemo<boolean>((): boolean => {
        let is = false;
        Object.keys(authPath)
            .map((key) => authPath[key])
            .forEach((el) => {
                if (pathname.includes(el)) {
                    is = true;
                }
            });
        return is;
    }, [pathname]);

    useEffect(() => {
        Auth2Service.status.subscribe((res) => {
            if (isAuthPath) {
                onAppReady();
                return;
            }
            if (!res.ready) {
                setOpacity(1);
                return;
            }
            if (res.authenticated === 'pending') {
                // Waiting for userDetails
                return;
            }
            if (res.authenticated) {
                onAppReady();
                return;
            }
            if (!isAuthPath) {
                Auth2Service.blockedPath.set(pathname);
                navigate(authPath.login);
                return;
            }
        });
    }, [pathname]);

    const onAppReady = async () => {
        // console.log('App is Ready', authReady, authenticated);
        await sleep(1);
        const id = setInterval(() => {
            setOpacity((currentValue) => {
                if (currentValue < 0) {
                    clearInterval(id);
                    return 0;
                }
                return currentValue - 0.1;
            });
        }, 30);
    };

    if (isAuthPath) {
        return props.children;
    }

    return (
        <>
            {opacity < 1 && props.children}
            {opacity > 0 && (
                <div style={{opacity}}>
                    <FullScreenLoader />
                </div>
            )}
        </>
    );
};
