import {useParams} from 'react-router-dom';
import {DeviceManager4} from './DeviceManager4/DeviceManager4';

export const ProjectDevices = () => {
    const {projectId} = useParams();

    return (
        <>
            <DeviceManager4 projectId={projectId} />
        </>
    );
};
