import {DeviceInterface} from './device.service';
import {HttpClient, HttpResponse} from './http-client.service';
import {urlBuilder} from '../../helpers/urlBuilder';

import {Api} from './API';

export interface ProjectFolderInterface {
    id: string;
    name: string;
    projectId: string;
    parentFolderId: string;
}

export type ProjectFolderEntity = ProjectFolderInterface & {
    folders: ProjectFolderInterface[];
    devices: DeviceInterface[];
};

const rootFolder = async (projectId: string): Promise<HttpResponse<ProjectFolderEntity>> => {
    return HttpClient.get(urlBuilder('project', projectId, 'folder'));
};

const get = async (projectId: string): Promise<HttpResponse<ProjectFolderEntity>> => {
    return HttpClient.get(urlBuilder('project', projectId, 'folder'));
};

const create = async (
    projectId: string,
    name: string,
    parentFolderId: string
): Promise<HttpResponse<ProjectFolderInterface>> => {
    return HttpClient.post(urlBuilder('project', projectId, 'folder'), {
        name,
        parentFolderId,
    });
};

const update = async (payload: ProjectFolderInterface): Promise<HttpResponse<ProjectFolderInterface>> => {
    return await HttpClient.put(urlBuilder(Api.folder, payload.id), payload);
};

const remove = async (id: string): Promise<HttpResponse<void>> => {
    return await HttpClient.delete<void>(urlBuilder(Api.folder, id));
};

export const ProjectFoldersService = {create, update, remove, get, rootFolder};
