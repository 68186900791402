import React, {useEffect, useState} from 'react';
import {MaterialIcon} from '../../../components/MaterialIcon';
import {WithTitle} from '../ProjectView';
import {ActionIcon, Button, Group, LoadingOverlay} from '@mantine/core';
import {ProjectService} from '../../../services/api-services/project.service';
import {ProjectCertificateInterface} from '../../../services/api-services/project-certificate.service';

export const CertificateUpload = (props: {projectId: string | undefined}) => {
    const {projectId} = props;
    const inputRef: any = React.useRef(null);

    const [loading, setLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<ProjectCertificateInterface[]>();

    useEffect(() => {
        fetch();
    }, []);

    if (!projectId) {
        return <></>;
    }

    const fetch = async () => {
        setLoading(true);
        const [res, err] = await ProjectService.certificate.list(projectId);
        if (res) {
            setFiles(res);
        }
        setLoading(false);
    };

    const upload = async (files: any) => {
        setLoading(true);
        const [res] = await ProjectService.certificate.upload(projectId, files[0]);
        await fetch();
        inputRef.current.value = '';
        setLoading(false);
    };

    const remove = async (certId: string) => {
        setLoading(true);
        await ProjectService.certificate.remove(certId);
        await fetch();
        setLoading(false);
    };

    const title = (
        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
            <MaterialIcon styles={{color: '#ACACAC'}} name='gpp_good' />
            <div style={{color: '#5C5C5C', marginLeft: 6}}>Certificates</div>
        </div>
    );
    return (
        <WithTitle title={title}>
            <LoadingOverlay zIndex={199} visible={loading} />
            <Group mb={10}>
                <input
                    style={{display: 'none'}}
                    ref={inputRef}
                    type='file'
                    onInput={(e: any) => upload(e.target?.files)}
                    accept='.cer'
                />
                <Button variant='subtle' onClick={() => inputRef?.current?.click()}>
                    Add certificate
                </Button>
            </Group>
            {files?.map((el) => (
                <div
                    key={el.id}
                    style={{
                        width: '100%',
                        height: 58,
                        backgroundColor: '#F4F4F4',
                        borderRadius: 12,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div style={{display: 'flex', marginLeft: 10, flexDirection: 'column'}}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <MaterialIcon styles={{marginRight: 8}} name='fingerprint' />
                            <div style={{fontSize: 12, color: '#5C5C5C'}}>{el.fileName}</div>
                        </div>
                    </div>

                    <ActionIcon onClick={() => remove(el.id)} color='orange' radius='xl' variant='filled' mr={10}>
                        <MaterialIcon name='delete' />
                    </ActionIcon>
                </div>
            ))}

            {!files?.length && (
                <div
                    style={{
                        width: '98%',
                        height: 58,
                        border: '2px dashed #D3D3D3',
                        borderRadius: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#5C5C5C',
                    }}
                >
                    <div>No certificates yet</div>
                </div>
            )}
        </WithTitle>
    );
};
