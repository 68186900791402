import React from 'react';

export type TableColumnConfig = Array<
    | false
    | undefined
    | null
    | {
          title: any;
          render: (_: any) => {};
      }
>;

export interface TableProps {
    columnConfig: TableColumnConfig;
    list: any[];
    noHeader?: boolean;
}

export const Table = (props: TableProps) => {
    const {columnConfig, list, noHeader} = props;

    const columns = columnConfig.map((col, i) => {
        // @ts-ignore
        return <th key={i}>{col?.title}</th>;
    });

    const rows = list.map((row, index) => {
        return (
            <tr key={index}>
                {columnConfig.map((col, i) => {
                    // @ts-ignore
                    return col && <td key={i}>{col.render(row)}</td>;
                })}
            </tr>
        );
    });

    return (
        <div className='table-container'>
            <table className='table'>
                {!noHeader && (
                    <thead>
                        <tr>{columns}</tr>
                    </thead>
                )}

                <tbody>{rows}</tbody>
            </table>
        </div>
    );
};
