import {Subject} from 'rxjs';

type Variant = 'error' | 'success' | 'warning';
type Notification = {msg: string; variant: Variant};

const _notifications = new Subject<Notification>();
const add = (msg: string, variant: Variant) => _notifications.next({msg, variant});
const notifications = _notifications.asObservable();

export const NotificationService = {
    notifications,
    add,
};
