import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link} from 'react-router-dom';
import * as React from 'react';
import styled from 'styled-components/macro';

const Container = styled.div`
    //background-color: #fff;
    margin: 10px 0;
    border-radius: 5px;
`;

export type BreadcrumbItem = {title?: string; path?: string};

type BreadcrumbsProps = {
    items: BreadcrumbItem[];
};
export const BreadCrumbs = (props: BreadcrumbsProps) => {
    return (
        <Container>
            <Breadcrumbs separator={<NavigateNextIcon style={{color: '#fff'}} fontSize='small' />}>
                {props.items
                    .filter((el) => el.title)
                    .map((el, i) => {
                        return el.title && <RichLink key={i} title={el.title} path={el.path} />;
                    })}
            </Breadcrumbs>
        </Container>
    );
};

type RichLinkProps = {
    title: string;
    path?: string;
};

const RichLink = (props: RichLinkProps) => {
    const {title, path} = props;
    return path ? (
        <Link style={{color: '#fff'}} to={path}>
            {title}
        </Link>
    ) : (
        <span style={{color: '#fff'}} className='text'>
            {title}
        </span>
    );
};
