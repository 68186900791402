import {useEffect} from 'react';
import {NotificationService} from '../services/notification.service';
import {useSnackbar} from 'notistack';

export const NotificationsDisplay = () => {
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        NotificationService.notifications.subscribe(({msg, variant}) => {
            enqueueSnackbar(msg, {variant});
        });
    }, []);

    return <></>;
};
