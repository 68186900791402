const get = <T>(key: string): undefined | T => {
    let res;
    try {
        const item = localStorage.getItem(key);
        if (item) {
            res = JSON.parse(item);
        }
    } catch (e) {
        console.log(e);
    }
    return res;
};

const set = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value));

const remove = (key: string) => localStorage.removeItem(key);

const clear = () => localStorage.clear();

export const LocalStorageService = {get, set, remove, clear};
