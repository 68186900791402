import {useState} from 'react';
import {Alert, Button, Group, LoadingOverlay, TextInput} from '@mantine/core';
import {usePasswordForm} from './usePasswordForm';
import {Auth2Service} from '../auth2.service';
import {ErrorListDisplayUL, Title} from './loginCommon';
import {useEmailField} from '../../../hooks/useEmailField';
import {NotificationService} from '../../notification.service';

export const PasswordForgetSubmit = () => {
    const [showError, setShowError] = useState<boolean>(false);
    const {email, setEmail, valid, kinlyEmail} = useEmailField();
    const onEmailInput = (e: any) => setEmail(e.target.value);
    const [loading, setLoading] = useState<boolean>(false);

    const [code, setCode] = useState('');
    const [sent, setSent] = useState(false);

    const submit = async (password: string) => {
        setLoading(true);
        if (!email || kinlyEmail || !code) return;
        try {
            const res = await Auth2Service.forgetPasswordSubmit(email, code, password);
            console.log(res);
        } catch (e: any) {
            console.dir(e);
            NotificationService.add(e.message, 'error');
        }
        setShowError(true);
        setLoading(false);
    };

    const sendCode = async () => {
        setShowError(true);
        if (!valid) return;
        setLoading(true);
        try {
            await Auth2Service.forgetPassword(email);
            setSent(true);
        } catch (e: any) {
            NotificationService.add(e.message, 'error');
        }
        setLoading(false);
    };

    const {form} = usePasswordForm(submit, 'Reset Password');

    const keyPress = (e: any) => e.keyCode === 13 && next();

    const next = () => {
        console.log(sent);
        if (sent) return;
        sendCode();
    };

    if (!sent) {
        return (
            <>
                <Title>Forgot your password?</Title>
                <TextInput
                    error={showError && !valid}
                    required
                    mb='md'
                    value={email}
                    onInput={onEmailInput}
                    label='Enter your email:'
                    onKeyDown={keyPress}
                    type='email'
                />
                {showError && !valid && (
                    <ErrorListDisplayUL>
                        <li>Email address is invalid.</li>
                        {kinlyEmail && <li>You can't reset the password for a @kinly.com account in this page.</li>}
                    </ErrorListDisplayUL>
                )}
                <Group grow mt='lg'>
                    <Button onClick={next}>Send</Button>
                </Group>
            </>
        );
    }

    return (
        <>
            <LoadingOverlay visible={loading} />
            <Alert color='violet' mb={20} variant='light'>
                <span>Enter the code you received by email and set a new password.</span>
            </Alert>

            <TextInput
                required
                mb='sm'
                value={code}
                onInput={(e: any) => setCode(e.target.value)}
                label='Code'
                error={showError && !code}
            />

            {showError && <ErrorListDisplayUL>{!code && <li>Code can not be empty.</li>}</ErrorListDisplayUL>}

            {form}
        </>
    );
};
