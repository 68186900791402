import {NavLink} from 'react-router-dom';
import styled from 'styled-components/macro';

const Container = styled.div`
    padding: 5px 25px 0;

    * {
        margin-right: 20px;
        cursor: pointer;
        padding-bottom: 8px !important;

        &:hover,
        &.active {
            border-bottom: 2px solid #280b56;
            color: #000;
            padding-bottom: 6px !important;
        }
    }
`;

export const HeaderMenu = () => {
    // const {user} = useContext(AuthContext);
    // const {customerId, projectId, isManager} = user;

    const links = {
        manager: [
            // {title: 'Projects', path: '/projects'},
            {title: 'Customers', path: '/customers'},
        ],
        // customer: [
        //     {title: 'Device Manager', path: `/project/${customerId}/${projectId}/device-manager`},
        //     {title: 'Forms', path: `/project/${customerId}/${projectId}/forms`},
        //     {title: 'Customization', path: `/project/${customerId}/${projectId}/customization`},
        // ]
    };

    const getLinks = links.manager;

    return (
        <Container>
            {getLinks.map((el, i) => (
                <NavLink className={({isActive}) => (isActive ? 'active' : '')} key={i} to={el.path}>
                    {el.title}
                </NavLink>
            ))}
        </Container>
    );
};
