import {HttpClient, HttpResponse} from './http-client.service';
import {urlBuilder} from '../../helpers/urlBuilder';

import {ObjectDefaultAttributes} from './models';
import {ProjectFoldersService} from './project-folders.service';

export interface DeviceInterface {
    name: string;
    serialNumber: string;
    macAddress: string;
    teamsUsername: string;
    teamsPassword: string;
    projectId: string;
    parentFolderId: string;

    //JPR 07/09/2022: Removing these as requested
    //skypeSignInAddress: string;
    //exchangeAddress: string;

    // TODO incomplete entity definition
    // project:
    // parentFolder:
}

export type DeviceEntity = DeviceInterface & ObjectDefaultAttributes;

const get = async (id: string): Promise<HttpResponse<DeviceEntity>> => {
    return await HttpClient.get<DeviceEntity>(urlBuilder('device', id));
};

const update = async (payload: DeviceEntity): Promise<HttpResponse<DeviceEntity>> => {
    return await HttpClient.put<DeviceEntity>(urlBuilder('device', payload.id), payload);
};

const remove = async (id: string): Promise<HttpResponse<void>> => {
    return await HttpClient.delete<void>(urlBuilder('device', id));
};

const create = async (payload: DeviceInterface): Promise<HttpResponse<DeviceEntity>> => {
    return await HttpClient.post(urlBuilder('device'), payload);
};

const list = async (projectId: string): Promise<HttpResponse<any>> => {
    return HttpClient.get(urlBuilder('project', projectId, 'device'));
};

export const DeviceService = {
    get,
    update,
    remove,
    create,
    list,
    folders: ProjectFoldersService,
};
