import {HttpClient, HttpResponse} from './http-client.service';
import {Api} from './API';

export interface ProjectImageInterface {
    id: string;
    fileName: string;
    data: string;
    projectId: string;
}

const upload = async (projectId: string, file: any): Promise<HttpResponse<ProjectImageInterface>> => {
    const form: FormData = new FormData();
    form.append('file', file);
    return await HttpClient.post(Api.projectImageUpload(projectId), form);
};

const remove = async (projectId: string): Promise<HttpResponse<void>> => {
    return await HttpClient.delete<void>(Api.projectImage(projectId));
};

const getImage = async (projectId: string): Promise<HttpResponse<any>> => {
    return await HttpClient.get(Api.projectImage(projectId));
};

export const ProjectImageService = {upload, remove, getImage};
