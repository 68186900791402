import {useParams} from 'react-router-dom';
import {ProjectService} from '../../../services/api-services/project.service';
import {FormDefinition, KeyValueObject} from '../../../components/Drawer/DrawerForm/DynamicForm';
import {useEffect, useState} from 'react';
import {Drawer, Alert, Button, Group, LoadingOverlay} from '@mantine/core';
import {DynamicForm2} from '../../../components/DynamicForm2/DynamicForm2';
import {useDynamicForm} from '../../../components/DynamicForm2/useDynamicForm';

export const ProjectInvite = () => {
    const {projectId} = useParams();
    const [open, setOpen] = useState(false);
    const [type, setType] = useState<'customer' | 'engineer' | ''>('');
    const options = [
        {
            key: 'engineer',
            title: 'Invite an Engineer',
            text: 'Allow access to this project for a non-Kinly subcontractor.',
        },
        {
            key: 'customer',
            title: 'Invite a Customer',
            text: ' Allow access to this project for a customer representative.',
        },
    ];

    const submit = async (result: KeyValueObject) => {
        if (!projectId || type === '') {
            return [];
        }
        return await ProjectService.inviteToEdit(projectId, result.email, type);
    };

    const success = () => {
        setOpen(false);
    };

    const formData: FormDefinition = {
        email: {title: 'Email'},
    };
    const form = useDynamicForm(submit, success);
    form.init({formData});

    useEffect(() => {
        return () => {
            setType('');
            form.reset();
        };
    }, [open]);

    useEffect(() => {
        form.reset();
    }, [type]);

    const changeType = (type: any) => setType(type);

    return (
        <>
            <span>You can invite someone to edit Teams Rooms configuration.</span>
            <span className='link' onClick={() => setOpen(true)}>
                Invite
            </span>
            <Drawer
                opened={open}
                padding='xl'
                size='xl'
                position='right'
                onClose={() => setOpen(false)}
                noCloseOnClickOutside={form.loading}
                noCloseOnEscape={form.loading}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: 'calc(100% - 30px)',
                    }}
                >
                    <LoadingOverlay zIndex={199} visible={form.loading} />
                    <InviteSelect options={options} value={type} onChange={changeType}>
                        {open && form.values && (
                            <DynamicForm2
                                formData={formData}
                                value={form.values}
                                errors={form.errors}
                                onChange={form.onChange}
                            />
                        )}
                    </InviteSelect>
                    <Group>
                        <Button fullWidth onClick={form.handleSubmit}>
                            Send Invitation
                        </Button>
                    </Group>
                </div>
            </Drawer>
        </>
    );
};

interface Props {
    value: string;
    onChange: (result: string) => any | void;
    options: {key: string; title: string; text: string}[];
    children?: any;
}

const InviteSelect = ({value, options, onChange, children}: Props) => {
    const style = {
        marginBottom: 10,
        cursor: 'pointer',
        transition: 'all ease 0.3s',
    };

    return (
        <div>
            {options.map((el) => {
                return (
                    <Alert
                        key={el.key}
                        style={style}
                        title={el.title}
                        variant={value === el.key ? 'outline' : 'light'}
                        color='violet'
                        sx={(theme) => ({
                            backgroundColor: value === el.key ? theme.colors.violet[0] : theme.colors.gray[0],
                            '&:hover': {
                                backgroundColor: theme.colors.violet[0],
                            },
                        })}
                        onClick={() => onChange(el.key)}
                    >
                        <>{el.text}</>
                        {value === el.key && <div style={{marginTop: 15}}>{children}</div>}
                    </Alert>
                );
            })}
        </div>
    );
};
