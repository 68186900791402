import React, {FormEvent, useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import {sleep} from '../../helpers/sleep';

export interface FormItemDefinition {
    title?: string;
    value?: string;
    id?: string;
    hint?: string;
    validation?: {
        required?: boolean;
        regex?: string;
    };
    errors?: string[];
    options?: {label: string; value: any}[];
}

type FormItem = FormItemDefinition & {id: string};
export type KeyValueObject = {[s: string]: string};
export type FormDefinition = {[s: string]: FormItemDefinition};

interface Props {
    formData: FormDefinition;
    hiddenFields?: string[];
    submit?: (result: KeyValueObject) => any;
    success?: (result: any) => any;
    submitButtonTitle?: string;
}

export const useDynamicForm = (submit: any = null, success: any = null) => {
    const {enqueueSnackbar} = useSnackbar();
    const [initialValues, setInitialValues] = useState<KeyValueObject>();
    const [values, setValues] = useState<KeyValueObject>();
    const [errors, setErrors] = useState<KeyValueObject>({});
    const [loading, setLoading] = useState(false);
    const [n, setN] = useState(0);

    useEffect(() => {
        // console.log(n);
        setN((s) => s + 1);
    }, [values]);

    const init = (props: Props) => {
        prepareValues(props.formData);
    };

    const prepareValues = (_formData: FormDefinition) => {
        if (values) {
            return;
        }
        const f: KeyValueObject = {};
        Object.keys(_formData).forEach((el) => (f[el] = _formData[el].value || ''));
        setValues(f);
        setInitialValues(f);
    };

    const onChange = async (result: KeyValueObject) => {
        setValues(result);
    };

    const handleSubmit = async () => {
        if (!submit || !values) {
            return;
        }
        try {
            setLoading(true);
            const [res, err, status] = await submit(values);
            setLoading(false);
            if (status?.toString().startsWith(2)) {
                enqueueSnackbar('Done!', {variant: 'success'});
                if (success) {
                    await success(res);
                }
            } else {
                onSubmitError(err);
            }
        } catch (e) {
            setLoading(false);
            enqueueSnackbar('Error processing your request.', {variant: 'error'});
            console.log('dynamic form error');
            console.log(e);
        }
    };

    const onSubmitError = (err: any) => {
        const e: any = {};
        try {
            Object.keys(err.data.errors).forEach((el) => {
                e[el.toLowerCase()] = err.data.errors[el][0];
            });
        } catch (e) {
            console.log('Error not as expected!');
        }
        setErrors(e);
    };

    const reset = () => {
        setValues(initialValues);
        setErrors({});
    };

    return {init, values, onChange, errors, handleSubmit, loading, reset};
};
