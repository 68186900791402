import React, {FormEvent, useEffect, useState} from 'react';
import {ScrollArea, Select, TextInput} from '@mantine/core';

export interface FormItemDefinition {
    title?: string;
    value?: string;
    id?: string;
    hint?: string;
    validation?: {
        required?: boolean;
        regex?: string;
    };
    errors?: string[];
    options?: {label: string; value: any}[];
}

type FormItem = FormItemDefinition & {id: string};
export type KeyValueObject = {[s: string]: string};
export type FormDefinition = {[s: string]: FormItemDefinition};

interface Props {
    formData: FormDefinition;
    hiddenFields?: string[];
    value: KeyValueObject;
    onChange: (result: KeyValueObject) => any | void;
    errors?: KeyValueObject;
}

export const DynamicForm2 = (props: Props) => {
    const {formData, hiddenFields, value, onChange, errors} = props;
    const [questions, setQuestions] = useState<{[s: string]: FormItem}>({});
    const [n, setN] = useState(0);

    useEffect(() => {
        setN((p) => p + 1);
        init();
    }, [formData]);

    const init = () => {
        prepareQuestions();
    };

    const prepareQuestions = () => {
        const q: {[s: string]: FormItem} = {};
        Object.keys(formData).forEach((el) => (q[el] = {...formData[el], id: el}));
        setQuestions(q);
    };

    const onInput = async (e: any, key: string) => {
        let v: string;
        if (e === null || e === '') {
            v = '';
        } else {
            v = typeof e === 'string' ? e : e.target.value;
        }

        onChange({
            ...value,
            [key]: v,
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement> | undefined = undefined) => {
        e?.preventDefault();
    };

    const renderForm = () => {
        if (!value) {
            return null;
        }
        return Object.keys(questions)
            .filter((el) => !hiddenFields?.includes(el))
            .map((el) => questions[el])
            .map((el) => {
                return (
                    <div style={{marginBottom: 20}} key={el.id}>
                        {el.options?.length ? (
                            <Select
                                label={el.title}
                                // placeholder={el.title}
                                searchable
                                clearable
                                nothingFound='No options'
                                description={el.hint}
                                data={el.options}
                                value={value[el.id]}
                                onChange={(e) => onInput(e, el.id)}
                            />
                        ) : (
                            <TextInput
                                // placeholder={el.title}
                                label={el.title}
                                description={el.hint}
                                radius='md'
                                size='sm'
                                error={errors && errors[el.id]}
                                value={value[el.id]}
                                onInput={(e) => onInput(e, el.id)}
                            />
                        )}
                    </div>
                );
            });
    };

    return (
        <ScrollArea>
            <form onSubmit={handleSubmit}>{renderForm()}</form>
        </ScrollArea>
    );
};
