import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {HeaderMenu} from './HeaderMenu';
import styled from 'styled-components/macro';
import {themeConfig} from '../../../static';
import Logo from '../../../assets/kinly.logo.svg';
import {Auth2Service} from '../../services/auth/auth2.service';
import {UserInfo} from '../../services/auth/auth.models';
import {MaterialIcon} from '../MaterialIcon';
import {Subscription} from 'rxjs';

export const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 199;
    background-color: #fff;
    box-shadow: ${themeConfig.colors.boxShadow};
`;

export const Main = styled.div`
    height: ${themeConfig.processedDimensions.appBar.height};
    padding: 0 ${themeConfig.processedDimensions.drawer.padding};
    background-color: ${themeConfig.colors.primaryColor};

    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #fff;
    font-size: 20px;
    font-weight: bold;
    box-sizing: border-box;
`;

export const UserName = styled.div`
    font-size: 16px;
    font-weight: normal;
`;

export const SignIn = styled.div`
    font-size: 16px;
    font-weight: normal;
    cursor: pointer;
`;

export const Icon = styled.span`
    cursor: pointer;
    padding: 4px;
    display: flex;
`;

export const UserAuth = styled.span`
    display: flex;
    align-items: center;
`;
export const ProductName = styled.div`
    background: -webkit-linear-gradient(-70deg, #db469f, #2188ff);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    font-weight: bold;
    font-size: 18px;
    margin-left: 20px;
    background-color: #fff;
`;

export const BrandingStyle = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
`;

export const AppHeader = () => {
    const [user, setUser] = useState<UserInfo | false>(false);
    let subscription: Subscription;

    useEffect(() => {
        subscription = Auth2Service.user.subscribe((res) => setUser(res));
        return () => subscription.unsubscribe();
    }, []);

    return (
        <>
            {user && (
                <Container>
                    <Main>
                        <Link to={''} className='no-decoration'>
                            <Branding />
                        </Link>

                        <UserAuth>
                            <UserName>{user?.email}</UserName>
                            <Link to='/logout' style={{color: '#fff', display: 'flex'}}>
                                <MaterialIcon name='logout' />
                            </Link>
                        </UserAuth>
                    </Main>
                    <HeaderMenu />
                </Container>
            )}
        </>
    );
};

const Branding = () => (
    <BrandingStyle>
        <img height={24} src={Logo} alt='Kinly' />
        <ProductName>Pre-Deployment</ProductName>
    </BrandingStyle>
);
