import {CSSProperties} from 'react';

type Props = {
    name: string;
    variant?: 'outlined' | 'round';
    styles?: CSSProperties;
};

export const MaterialIcon = (props: Props) => {
    const {name, variant, styles} = props;
    return (
        <span className={`material-icons${variant ? '-' + variant : ''}`} style={{...styles, ...props.styles}}>
            {name}
        </span>
    );
};
