import {FirePage} from '../components/FirePage';
import {Table, TableColumnConfig} from '../components/Table';
import {useContext, useEffect, useState} from 'react';
import {ProjectEntity, ProjectService} from '../services/api-services/project.service';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {CustomerInterface, CustomerService} from '../services/api-services/customer.service';
import {BreadcrumbItem} from '../components/BreadCrumbs';
import {Button, Group, LoadingOverlay, Modal} from '@mantine/core';
import {FormDefinition} from '../components/Drawer/DrawerForm/DynamicForm';
import {DrawerForm} from '../components/Drawer/DrawerForm/DrawerForm';
import {DeleteDialog} from '../components/DeleteDialog';
import {UserPermissions} from '../services/auth/auth.models';
import {Auth2Service} from '../services/auth/auth2.service';
import {Subscription} from 'rxjs';

export const ProjectList = () => {
    const [customer, setCustomer] = useState<CustomerInterface | null>(null);
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState<ProjectEntity[]>();
    const {customerId} = useParams();
    const navigate = useNavigate();
    const breadcrumb: BreadcrumbItem[] = [{title: 'Customers', path: '/customer'}, {title: customer?.name}];
    const [permissions, setPermissions] = useState<UserPermissions>();
    let subscription: Subscription;

    useEffect(() => {
        subscription = Auth2Service.permissions.subscribe((res) => setPermissions(res));
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        getProjects();
        getCustomer();
    }, [customerId]);

    const getProjects = async () => {
        if (!customerId) {
            return;
        }
        setLoading(true);
        const [res] = await CustomerService.projects(customerId);
        setLoading(false);
        if (res) {
            setList(res);
        }
    };

    const getCustomer = async () => {
        if (!customerId) {
            return;
        }
        const [res] = await CustomerService.get(customerId);
        if (res) {
            setCustomer(res);
        }
    };

    const removeProject = async (id: string) => await ProjectService.remove(id);

    const columnConfig: TableColumnConfig = [
        {
            title: 'Project Name',
            render: (item: ProjectEntity) => <Link to={`/project/${item.id}`}>{item.name}</Link>,
        },
        {
            title: 'Reference',
            render: (item: ProjectEntity) => <span>{item.reference || '-'}</span>,
        },
        {
            title: 'City',
            render: (item: ProjectEntity) => <span>{item.city || '-'}</span>,
        },
        permissions?.project.remove && {
            title: '',
            render: (item: ProjectEntity) => (
                <DeleteDialog name={item.name} submit={() => removeProject(item.id)} success={getProjects} />
            ),
        },
    ];

    const formData: FormDefinition = {
        customerId: {
            title: 'customerId',
            value: customerId,
        },
        name: {
            title: 'Project Name',
            validation: {
                required: true,
            },
        },
        reference: {
            title: 'Reference',
        },
        address1: {
            title: 'Address 1',
        },
        address2: {
            title: 'Address 2',
        },
        city: {
            title: 'City',
        },
        postcode: {
            title: 'Post Code',
        },
        contactName: {
            title: 'Contact Name',
        },
        phone: {
            title: 'Phone',
        },
        email: {
            title: 'Email',
        },
    };

    const customerEditForm = {
        name: {
            title: 'Name',
            value: customer?.name,
        },
        id: {
            title: '',
            value: customerId,
        },
    };

    const addNewProject = async (formValue: any) => {
        return await ProjectService.create(formValue);
    };

    const addProjectSuccess = (result: any) => navigate('/project/' + result.id);

    const handleCustomerUpdate = async (payload: any) => {
        if (!customerId) {
            return;
        }
        return await CustomerService.update(customerId, payload.name);
    };

    return (
        <FirePage title={customer?.name || ''} breadcrumb={breadcrumb}>
            <div style={{display: 'block', position: 'relative', minHeight: 200}}>
                <LoadingOverlay zIndex={199} visible={loading} />
                <Group>
                    {permissions?.project.create && (
                        <DrawerForm
                            formData={formData}
                            hiddenFields={['customerId']}
                            openButton={<Button>Add new Project</Button>}
                            title='Add new Project'
                            submit={addNewProject}
                            success={addProjectSuccess}
                            submitButtonTitle='Create Project'
                        />
                    )}
                    {permissions?.customer.edit && (
                        <DrawerForm
                            formData={customerEditForm}
                            hiddenFields={['id']}
                            openButton={<Button>Edit Customer</Button>}
                            submit={handleCustomerUpdate}
                            success={getCustomer}
                            title='Edit Customer'
                            submitButtonTitle='Update Customer'
                        />
                    )}
                </Group>
                {list && <Table columnConfig={columnConfig} list={list} />}
            </div>
        </FirePage>
    );
};
