import {HttpClient, HttpResponse} from './http-client.service';
import {urlBuilder} from '../../helpers/urlBuilder';

import {ProjectEntity} from './project.service';

export interface CustomerInterface {
    id: string;
    name: string;
    projectCount: string;
    created: string;
    modified: string;
}

const list = async (): Promise<HttpResponse<CustomerInterface[]>> => {
    return await HttpClient.get<CustomerInterface[]>(urlBuilder('customer'));
};

const get = async (id: string): Promise<HttpResponse<CustomerInterface>> => {
    return await HttpClient.get<CustomerInterface>(urlBuilder('customer', id));
};

const projects = async (customerId: string): Promise<HttpResponse<ProjectEntity[]>> => {
    return await HttpClient.get<ProjectEntity[]>(urlBuilder('customer', customerId, 'projects'));
};

const create = async (name: string): Promise<HttpResponse<CustomerInterface>> => {
    return await HttpClient.post<CustomerInterface>(urlBuilder('customer'), {
        name,
    });
};

const update = async (id: string, name: string): Promise<HttpResponse<CustomerInterface>> => {
    return await HttpClient.put<CustomerInterface>(urlBuilder('customer', id), {
        name,
    });
};

const remove = async (id: string): Promise<HttpResponse<any>> => {
    return await HttpClient.delete<any>(urlBuilder('customer', id));
};

export const CustomerService = {list, get, create, update, remove, projects};
