import styled from 'styled-components/macro';

export const ErrorListDisplayUL = styled.ul`
    font-size: 12px;
    margin-bottom: 10px;
    color: #f03e3e;
    list-style: none;
    padding: 0;
`;

export const Title = styled.div`
    font-size: 18px;
    margin-bottom: 20px;
`;

export const OtherOption = styled.div`
    cursor: pointer;
    text-decoration: underline;
    margin-top: 10px;
    padding: 5px;
`;
