import {ObjectDefaultAttributes} from './models';
import {HttpClient, HttpResponse} from './http-client.service';
import {urlBuilder} from '../../helpers/urlBuilder';
import {ProjectImageService} from './project-image.service';
import {ProjectCertificateInterface, ProjectCertificateService} from './project-certificate.service';
import {ProjectFoldersService} from './project-folders.service';

export interface ProjectInterface {
    customerId: string;
    reference: string;
    name: string;
    address1: string;
    address2: string;
    city: string;
    postcode: string;
    contactName: string;
    phone: string;
    email: string;
    // customer:
    // image:
    // country:
}

export type ProjectEntity = ProjectInterface &
    ObjectDefaultAttributes & {
        certificates: ProjectCertificateInterface[];
        folders: [];
    };

const get = async (id: string): Promise<HttpResponse<ProjectEntity>> => {
    return await HttpClient.get(urlBuilder('project', id));
};

const list = async (customerId: string): Promise<HttpResponse<ProjectEntity[]>> => {
    return await HttpClient.get<ProjectEntity[]>(urlBuilder('project'));
};

const update = async (id: string, payload: ProjectInterface): Promise<HttpResponse<ProjectEntity>> => {
    return await HttpClient.put<ProjectEntity>(urlBuilder('project', id), payload);
};

const remove = async (id: string): Promise<HttpResponse<void>> => {
    return await HttpClient.delete<void>(urlBuilder('project', id));
};

const create = async (payload: ProjectInterface): Promise<HttpResponse<ProjectEntity>> => {
    return await HttpClient.post(urlBuilder('project'), payload);
};

const inviteToEdit = async (
    projectId: string,
    email: string,
    inviteType: 'customer' | 'engineer'
): Promise<HttpResponse<any>> => {
    return await HttpClient.post(urlBuilder('profile', 'invite'), {email, projectId, inviteType});
};

export const ProjectService = {
    get,
    list,
    update,
    remove,
    create,
    inviteToEdit,
    certificate: ProjectCertificateService,
    folder: ProjectFoldersService,
    image: ProjectImageService,
};
