import React, {FormEvent, useEffect, useState} from 'react';
import {Button, Group, LoadingOverlay, ScrollArea, Select, TextInput} from '@mantine/core';
import {useSnackbar} from 'notistack';

export interface FormItemDefinition {
    title?: string;
    value?: string;
    id?: string;
    hint?: string;
    validation?: {
        required?: boolean;
        regex?: string;
    };
    conditions?: {[key: string]: string | string[]};
    type?: 'password';
    errors?: string[];
    options?: {label: string; value: any}[];
}

type FormItem = FormItemDefinition & {id: string};
export type KeyValueObject = {[s: string]: string};
export type FormDefinition = {[s: string]: FormItemDefinition};

interface Props {
    formData: FormDefinition;
    hiddenFields?: string[];
    submit?: (result: KeyValueObject) => any;
    success?: (result: any) => any;
    submitButtonTitle?: string;
}

export const DynamicForm = (props: Props) => {
    const {formData, hiddenFields, submitButtonTitle} = props;
    const {submit, success} = props;
    const {enqueueSnackbar} = useSnackbar();
    const [values, setValues] = useState<KeyValueObject | null>(null);
    const [errors, setErrors] = useState<KeyValueObject>({});
    const [questions, setQuestions] = useState<{[s: string]: FormItem}>({});
    const [loading, setLoading] = useState(false);
    // const [n, setN] = useState(0);

    useEffect(() => {
        // setN((p) => p + 1);
        // console.log({count: n, formData});
        init();
    }, [formData]);

    const init = () => {
        prepareValues();
        prepareQuestions();
    };

    const prepareValues = () => {
        const f: KeyValueObject = {};
        Object.keys(formData).forEach((el) => (f[el] = formData[el].value || ''));
        setValues(f);
    };

    const prepareQuestions = () => {
        const q: {[s: string]: FormItem} = {};
        Object.keys(formData).forEach((el) => (q[el] = {...formData[el], id: el}));
        setQuestions(q);
    };

    const onInput = async (e: any, key: string) => {
        let v: string;
        if (e === null || e === '') {
            v = '';
        } else {
            v = typeof e === 'string' ? e : e.target.value;
        }

        setValues((s) => ({
            ...s,
            [key]: v,
        }));
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement> | undefined = undefined) => {
        e?.preventDefault();
        if (!submit || !values) {
            return;
        }
        try {
            setLoading(true);
            const [res, err, status] = await submit(values);
            setLoading(false);
            if (status.toString().startsWith(2) && success) {
                enqueueSnackbar('Done!', {variant: 'success'});
                await success(res);
            } else {
                onSubmitError(err);
            }
        } catch (e) {
            console.log('dynamic form error');
            console.log(e);
        }
    };

    const onSubmitError = (err: any) => {
        const e: any = {};
        try {
            Object.keys(err.data.errors).forEach((el) => {
                e[el.toLowerCase()] = err.data.errors[el][0];
            });
        } catch (e) {
            console.log('Error not as expected!');
        }
        setErrors(e);
    };

    const renderForm = () => {
        if (!values) {
            return null;
        }
        return Object.keys(questions)
            .filter((el) => !hiddenFields?.includes(el))
            .map((el) => questions[el])
            .map((el) => {
                let conditional = true;

                if (!!el.conditions) {
                    Object.entries(el.conditions).forEach((condition) => {
                        if (typeof condition[1] == 'string') {
                            if (values[condition[0]] != condition[1]) {
                                conditional = false;
                            }
                        } else {
                            conditional = conditional && condition[1].includes(values[condition[0]]);
                        }
                    });
                }

                if (conditional) {
                    return (
                        <div style={{marginBottom: 20}} key={el.id}>
                            {el.options?.length ? (
                                <Select
                                    label={el.title}
                                    // placeholder={el.title}
                                    searchable
                                    clearable
                                    nothingFound='No options'
                                    description={el.hint}
                                    data={el.options}
                                    value={values[el.id]}
                                    onChange={(e) => onInput(e, el.id)}
                                />
                            ) : (
                                <TextInput
                                    // placeholder={el.title}
                                    label={el.title}
                                    description={el.hint}
                                    radius='md'
                                    size='sm'
                                    autoComplete='off'
                                    type={el.type || 'text'}
                                    error={errors && errors[el.id]}
                                    value={values[el.id]}
                                    onInput={(e) => onInput(e, el.id)}
                                />
                            )}
                        </div>
                    );
                }

                return;
            });
    };

    return (
        <>
            <LoadingOverlay zIndex={199} visible={loading} />
            <ScrollArea>
                <form onSubmit={handleSubmit} style={{paddingRight: 20}}>
                    {renderForm()}
                </form>
            </ScrollArea>
            <Group style={{padding: '15px 0'}}>
                <Button fullWidth onClick={(e: any) => handleSubmit(e)}>
                    {submitButtonTitle || 'Submit'}
                </Button>
            </Group>
        </>
    );
};
