import {BehaviorSubject, Observable} from 'rxjs';
import {AuthStatus, UserInfo, UserPermissions} from './auth.models';
import {filter, map} from 'rxjs/operators';
import {AmplifyActions} from './amplify.actions';
import {LocalStorageService} from '../localstorage.service';
import {CognitoUser} from 'amazon-cognito-identity-js';

const blockedPathKey = 'next';
const getBlockedPath: string = LocalStorageService.get(blockedPathKey) || '/';
const setBlockedPath = (path: string) => LocalStorageService.set(blockedPathKey, path);
const clearBlockedPath = () => LocalStorageService.remove(blockedPathKey);

const _user = new BehaviorSubject<UserInfo | null | false>(null);
// @ts-ignore
const user: Observable<UserInfo | false> = _user.asObservable().pipe(filter((res) => res !== null));

const status: Observable<AuthStatus> = user.pipe(
    map((_u): AuthStatus => {
        return _u === null
            ? {ready: false, authenticated: 'pending'}
            : {
                  ready: true,
                  authenticated: !!_u,
              };
    })
);

const permissions: Observable<UserPermissions> = user.pipe(
    map((u) => {
        const isAdmin = u ? u.isAdmin : false;
        return new UserPermissions(isAdmin);
    })
);

const getCognitoUser = async () => {
    const u = await AmplifyActions.getCognitoSessionUser();
    _user.next(u);
    return u;
};

AmplifyActions.cognitoAuthStatus.subscribe((res) => {
    console.log('Cognito Event: ' + res.event);
    if (['pending', 'signIn', 'signUp', 'signOut', 'tokenRefresh_failure'].includes(res.event)) {
        getCognitoUser();
    }
});

const logout = AmplifyActions.signOut;
const login = AmplifyActions.signIn;
const federatedLogin = AmplifyActions.federatedSignIn;
const completeNewPassword = async (user: CognitoUser, newPassword: string) => {
    return await AmplifyActions.completeNewPassword(user, newPassword);
};

const forgetPassword = AmplifyActions.forgetPassword;
const forgetPasswordSubmit = AmplifyActions.forgetPasswordSubmit;

export const Auth2Service = {
    user,
    status,
    permissions,
    logout,
    login,
    federatedLogin,
    completeNewPassword,
    blockedPath: {
        set: setBlockedPath,
        get: getBlockedPath,
        clear: clearBlockedPath,
    },
    getCognitoUser,
    forgetPassword,
    forgetPasswordSubmit,
};
