import {FirePage} from '../components/FirePage';
import {useEffect, useState} from 'react';
import {CustomerInterface, CustomerService} from '../services/api-services/customer.service';
import {Table, TableColumnConfig} from '../components/Table';
import {Link, useNavigate} from 'react-router-dom';
import {Button, LoadingOverlay} from '@mantine/core';
import {DrawerForm} from '../components/Drawer/DrawerForm/DrawerForm';
import {FormDefinition} from '../components/Drawer/DrawerForm/DynamicForm';
import {DeleteDialog} from '../components/DeleteDialog';
import {UserPermissions} from '../services/auth/auth.models';
import {Auth2Service} from '../services/auth/auth2.service';
import {Subscription} from 'rxjs';

export const CustomersList = () => {
    const [list, setList] = useState<CustomerInterface[]>();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState<UserPermissions>();
    let subscription: Subscription;

    useEffect(() => {
        subscription = Auth2Service.permissions.subscribe((res) => setPermissions(res));
        return () => subscription.unsubscribe();
    }, []);

    useEffect(() => {
        getCustomerList();
    }, []);

    const getCustomerList = async () => {
        setLoading(true);
        const [res] = await CustomerService.list();
        if (res) {
            setList(res);
        }
        setLoading(false);
    };

    const removeCustomer = async (id: string) => await CustomerService.remove(id);

    const columnConfig: TableColumnConfig = [
        {
            title: 'Customer Name',
            render: (item: CustomerInterface) => <Link to={`/customer/${item.id}`}>{item.name}</Link>,
        },
        {
            title: 'Projects',
            render: (item: CustomerInterface) => <>{item.projectCount}</>,
        },
        permissions?.customer.remove && {
            title: '',
            render: (item: CustomerInterface) => (
                <DeleteDialog submit={() => removeCustomer(item.id)} name={item.name} success={getCustomerList} />
            ),
        },
    ];

    const formData: FormDefinition = {
        name: {
            title: 'Name',
        },
    };

    const addNewCustomer = async (formValue: any) => {
        return await CustomerService.create(formValue.name);
    };

    const onAddCustomerSuccess = async (customer: CustomerInterface) => {
        navigate('/customer/' + customer.id);
    };

    return (
        <FirePage title='Customers'>
            <div style={{display: 'block', position: 'relative', minHeight: 200}}>
                <LoadingOverlay zIndex={199} visible={loading} />
                {permissions?.customer.create && (
                    <DrawerForm
                        formData={formData}
                        openButton={<Button>Add Customer</Button>}
                        title='Add new Customer'
                        submit={addNewCustomer}
                        success={onAddCustomerSuccess}
                        submitButtonTitle='Create Customer'
                    />
                )}
                {list && <Table columnConfig={columnConfig} list={list} />}
            </div>
        </FirePage>
    );
};
