import {useEffect} from 'react';
import {Auth2Service} from './auth2.service';
import {FullScreenLoader} from '../../components/FullScreenLoader';

export const Logout = () => {
    useEffect(() => {
        Auth2Service.logout();
    }, []);

    return <FullScreenLoader />;
};
