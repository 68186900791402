import {ProjectEntity, ProjectService} from '../../../services/api-services/project.service';
import {Table, TableColumnConfig} from '../../../components/Table';
import {WithTitle} from '../ProjectView';
import {Button, LoadingOverlay, Group} from '@mantine/core';
import {FormDefinition, KeyValueObject} from '../../../components/Drawer/DrawerForm/DynamicForm';
import {DrawerForm} from '../../../components/Drawer/DrawerForm/DrawerForm';
import {useEffect, useState} from 'react';
import {UserPermissions} from '../../../services/auth/auth.models';
import {Auth2Service} from '../../../services/auth/auth2.service';
import {Subscription} from 'rxjs';

export const ProjectDetails = (props: {project?: ProjectEntity; onUpdate: () => any}) => {
    const {project} = props;
    const [permissions, setPermissions] = useState<UserPermissions>();
    let subscription: Subscription;

    useEffect(() => {
        subscription = Auth2Service.permissions.subscribe((res) => setPermissions(res));
        return () => subscription.unsubscribe();
    }, []);

    if (!project) {
        return (
            <WithTitle title='Project Details'>
                <div
                    style={{
                        display: 'block',
                        position: 'relative',
                        minHeight: 150,
                    }}
                >
                    <LoadingOverlay zIndex={199} visible={true} />
                </div>
            </WithTitle>
        );
    }
    const formData: FormDefinition = {
        id: {title: 'id', value: project.id},
        name: {title: 'Name', value: project.name},
        reference: {title: 'Reference', value: project.reference},
        city: {title: 'City', value: project.city},
        address1: {title: 'Address 1', value: project.address1},
        address2: {title: 'Address 2', value: project.address2},
        postcode: {title: 'Post Code', value: project.postcode},
        contactName: {title: 'Contact Name', value: project.contactName},
        phone: {title: 'Phone', value: project.phone},
        email: {title: 'Email', value: project.email},
    };

    const properties = [
        {key: 'name', label: 'Name'},
        {key: 'reference', label: 'Reference'},
        {key: 'city', label: 'City'},
        {key: 'address1', label: 'Address 1'},
        {key: 'address2', label: 'Address 2'},
        {key: 'postcode', label: 'Post Code'},
        {key: 'contactName', label: 'Contact Name'},
        {key: 'phone', label: 'Phone'},
        {key: 'email', label: 'Email'},
    ];

    const prepTable = () => {
        return properties.map((property) => {
            // @ts-ignore
            return {label: property.label, value: project[property.key]};
        });
    };

    const tableConfig: TableColumnConfig = [
        {
            title: '',
            render: (item: any) => item.label,
        },
        {
            title: '',
            render: (item: any) => (item.value ? item.value : '-'),
        },
    ];

    const editButton = <Button variant='subtle'>Edit Project Detail</Button>;

    const handleFormSubmit = async (result: KeyValueObject) => {
        return await ProjectService.update(project.id, result as any);
    };

    return (
        <WithTitle title='Project Details'>
            {permissions?.project.edit && (
                <Group>
                    <DrawerForm
                        title={'Edit ' + project.name}
                        formData={formData}
                        hiddenFields={['id']}
                        openButton={editButton}
                        success={props.onUpdate}
                        submit={handleFormSubmit}
                        submitButtonTitle='Update Project'
                    />
                </Group>
            )}

            {project && <Table columnConfig={tableConfig} list={prepTable()} noHeader />}
        </WithTitle>
    );
};
