import {useParams} from 'react-router-dom';
import {useContext} from 'react';
import {CheckListService} from '../../../services/checklist/checklist.service';
import {ChecklistSection} from '../../../services/checklist/checklist.model';
import {WithTitle} from '../ProjectView';
import {Table, TableColumnConfig} from '../../../components/Table';
import {Badge, LoadingOverlay, Paper, Text} from '@mantine/core';
import {FormDefinition, KeyValueObject} from '../../../components/Drawer/DrawerForm/DynamicForm';
import {DrawerForm} from '../../../components/Drawer/DrawerForm/DrawerForm';
import {ProjectViewContext} from '../projectViewContext';

export const ProjectChecklist = (props: {type: 'shortlist' | 'sections'; title: string}) => {
    const {projectId} = useParams<{projectId: string}>();
    const {loading, getChecklist, checklist} = useContext(ProjectViewContext);
    const sections = checklist[props.type];

    const tableConfig: TableColumnConfig = [
        {
            title: '',
            render: (item: ChecklistSection) => item.displayName,
        },
        {
            title: '',
            render: (item: ChecklistSection) => (
                <>
                    <ChecklistSectionForm section={item} projectId={projectId as string} onUpdate={getChecklist} />
                    {item.incomplete && (
                        <Badge color='red' variant='outline' style={{margin: '0 10px'}}>
                            incomplete
                        </Badge>
                    )}
                </>
            ),
        },
    ];

    return (
        <>
            <WithTitle title={props.title}>
                <div style={{display: 'block', position: 'relative', minHeight: 100}}>
                    <LoadingOverlay zIndex={199} visible={loading} />
                    {sections && <Table columnConfig={tableConfig} list={sections} noHeader />}
                </div>
            </WithTitle>
        </>
    );
};

const ChecklistSectionForm = (props: {section: ChecklistSection; projectId: string; onUpdate: () => any}) => {
    const formData: FormDefinition = {};
    props.section.checklistQuestions.forEach((el) => {
        formData[el.id] = {
            title: el.question,
            value: el.value,
            options: el.checklistOptions as any,
            hint: el.hint,
            conditions: el.conditions,
        };
    });

    const handleSubmit = async (result: KeyValueObject) => {
        return await CheckListService.update(props.projectId, result);
    };

    const openButton = (
        <Text variant='link' style={{display: 'inline-block', cursor: 'pointer', marginLeft: 15}}>
            UPDATE
        </Text>
    );

    return (
        <DrawerForm
            title={props.section.displayName}
            formData={formData}
            openButton={openButton}
            submit={handleSubmit}
            success={props.onUpdate}
        />
    );
};
