import {HttpClient, HttpResponse} from './http-client.service';
import {urlBuilder} from '../../helpers/urlBuilder';

export interface ProjectCertificateInterface {
    id: string;
    fileName: string;
    data: string;
    projectId: string;
}

const list = async (projectId: string): Promise<HttpResponse<ProjectCertificateInterface[]>> => {
    return await HttpClient.get<ProjectCertificateInterface[]>(urlBuilder('project', projectId, 'certificate'));
};

const upload = async (projectId: string, file: any): Promise<HttpResponse<ProjectCertificateInterface>> => {
    const formData = new FormData();
    formData.append('file', file);
    const url = urlBuilder('project', projectId, 'certificate', 'upload');
    return await HttpClient.post<ProjectCertificateInterface>(url, formData);
};

const getById = async (certId: string): Promise<HttpResponse<ProjectCertificateInterface>> => {
    return await HttpClient.get<ProjectCertificateInterface>(urlBuilder('certificate', certId));
};

const remove = async (certId: string): Promise<HttpResponse<void>> => {
    return await HttpClient.delete(urlBuilder('certificate', certId));
};

export const ProjectCertificateService = {list, upload, getById, remove};
