import {Hero} from './Hero';
import React from 'react';
import {BreadcrumbItem} from './BreadCrumbs';
import {Container, Paper} from '@mantine/core';

type Props = {
    children: any;
    title?: string;
    breadcrumb?: BreadcrumbItem[];
    transparent?: boolean;
};

export const FirePage = (props: Props) => {
    return (
        <div>
            <Hero title={props.title} breadcrumb={props.breadcrumb} />
            {/*{*/}
            {/*    <Paper padding="md" shadow="md" radius="md"*/}
            {/*           sx={(theme) => {*/}
            {/*               return !props.transparent ? {} : {*/}
            {/*                   backgroundColor: 'transparent',*/}
            {/*                   boxShadow: 'none'*/}
            {/*               }*/}
            {/*           }}*/}
            {/*    >*/}
            {/*        {props.children}*/}
            {/*    </Paper>*/}
            {/*}*/}
            {props.transparent ? (
                <Container size='xl'>{props.children}</Container>
            ) : (
                <Paper padding='md' shadow='md' radius='md' style={{margin: '8px auto 0px', maxWidth: 1320}}>
                    {props.children}
                </Paper>
            )}
        </div>
    );
};
