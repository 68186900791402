import Logo from '../../assets/kinly-alt.logo.svg';
import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';

export const FullScreenLoader = () => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100vh',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#fff',
        }}
    >
        <div>
            <img className='logo' width='110' height='35' src={Logo} alt='Kinly' />
        </div>
        <div
            style={{
                color: 'rgb(40, 11, 86)',
                width: '120px',
                marginTop: '20px',
            }}
        >
            <LinearProgress color='inherit' />
        </div>
    </div>
);
