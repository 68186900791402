import {FirePage} from '../../components/FirePage';
import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ProjectEntity, ProjectService} from '../../services/api-services/project.service';
import {BreadcrumbItem} from '../../components/BreadCrumbs';
import {CustomerInterface, CustomerService} from '../../services/api-services/customer.service';
import {Grid, Paper} from '@mantine/core';
import {ProjectDetails} from './ProjectDetail/ProjectDetail';
import {ProjectChecklist} from './ProjectChecklist/ProjectChecklist';
import {ProjectDevices} from './ProjectDevices/ProjectDevices';
import {ProjectInvite} from './Invite/ProjectInvite';
import {ImageUpload} from './FileManager/ImageUpload';
import {CertificateUpload} from './FileManager/CertificateUpload';
import {ProjectViewContextProvider} from './projectViewContext';
import {UserPermissions} from '../../services/auth/auth.models';
import {Auth2Service} from '../../services/auth/auth2.service';
import {Subscription} from 'rxjs';

export const ProjectView = () => {
    const [project, setProject] = useState<ProjectEntity>();
    const [customer, setCustomer] = useState<CustomerInterface>();
    const {projectId} = useParams<{projectId: string}>();
    const [permissions, setPermissions] = useState<UserPermissions>();
    let subscription: Subscription;

    useEffect(() => {
        subscription = Auth2Service.permissions.subscribe((res) => setPermissions(res));
        return () => subscription.unsubscribe();
    }, []);

    const breadcrumb: BreadcrumbItem[] = [
        {
            title: 'Customers',
            path: '/customer',
        },
        {
            title: customer?.name,
            path: `/customer/${customer?.id}`,
        },
        {
            title: project?.name,
        },
    ];

    useEffect(() => {
        getProject();
    }, [projectId]);

    const getProject = async () => {
        if (!projectId) {
            return;
        }
        const [res] = await ProjectService.get(projectId);
        setProject(res);
        getCustomer(res.customerId);
    };

    const getCustomer = async (customerId: string) => {
        const [res] = await CustomerService.get(customerId);
        if (res) {
            setCustomer(res);
        }
    };

    return (
        <ProjectViewContextProvider projectId={projectId}>
            <FirePage title={project?.name} breadcrumb={breadcrumb} transparent>
                {
                    <Grid>
                        <Grid.Col md={6} lg={6}>
                            <Paper
                                style={{marginBottom: 15, minHeight: 100, position: 'relative'}}
                                padding='md'
                                shadow='md'
                                radius='md'
                            >
                                <ProjectDetails project={project} onUpdate={getProject} />
                            </Paper>
                            <Paper
                                padding='md'
                                shadow='md'
                                radius='md'
                                style={{marginBottom: 15, minHeight: 100, position: 'relative'}}
                            >
                                <ProjectChecklist type='sections' title='Checklist' />
                            </Paper>
                        </Grid.Col>
                        <Grid.Col md={6} lg={6}>
                            {permissions?.project.invite && (
                                <Paper style={{marginBottom: 15}} padding='md' shadow='md' radius='md'>
                                    <ProjectInvite />
                                </Paper>
                            )}
                            <Paper style={{marginBottom: 15}} padding='md' shadow='md' radius='md'>
                                <WithTitle title='Devices'>
                                    <ProjectDevices />
                                </WithTitle>
                            </Paper>
                            <Paper
                                padding='md'
                                shadow='md'
                                radius='md'
                                style={{marginBottom: 15, position: 'relative'}}
                            >
                                <ProjectChecklist type='shortlist' title='Teams Rooms Settings' />
                            </Paper>
                            <Paper style={{marginBottom: 15}} padding='md' shadow='md' radius='md'>
                                <CertificateUpload projectId={projectId} />
                            </Paper>

                            <Paper style={{marginBottom: 15}} padding='md' shadow='md' radius='md'>
                                <ImageUpload projectId={projectId} />
                            </Paper>
                        </Grid.Col>
                    </Grid>
                }
            </FirePage>
        </ProjectViewContextProvider>
    );
};

export const WithTitle = (props: {children: any; title?: any}) => {
    const {title} = props;
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 100,
                height: '100%',
                position: 'relative',
            }}
        >
            {props.title && (
                <div
                    style={{
                        borderBottom: '2px solid var(--gray-titanium)',
                        margin: '0 -16px 15px',
                        padding: '0px 15px 15px',
                    }}
                >
                    {title}
                </div>
            )}
            {props.children}
        </div>
    );
};
