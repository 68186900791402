import {Button, Drawer} from '@mantine/core';
import {cloneElement, useEffect, useState} from 'react';
import {DynamicForm, FormDefinition, KeyValueObject} from './DynamicForm';

interface Props {
    title?: string;
    formData: FormDefinition;
    isOpen?: boolean;
    hiddenFields?: string[];
    position?: 'right' | 'left';
    openButton?: any;
    submitButtonTitle?: string;
    submit?: (result: KeyValueObject) => any | void;
    success?: (result: any) => any | void;
    openState?: {isOpen: any; setIsOpen: any};
}

export const DrawerForm = (props: Props) => {
    const {openState, title, position, openButton, formData, submitButtonTitle} = props;
    const {submit, success} = props;

    const [opened, setOpened] = useState(false);
    const onOpenClick = () => setOpened(true);

    const close = () => {
        if (openState) {
            openState.setIsOpen(false);
            return;
        }
        setOpened(false);
    };

    const successProxy = async (result: any) => {
        close();
        if (success) {
            return await success(result);
        }
    };

    return (
        <>
            <Drawer
                title={title}
                opened={openState === undefined ? opened : openState.isOpen}
                position={position || 'right'}
                onClose={() => close()}
                padding='xl'
                size='xl'
                // zIndex={402}
            >
                <div
                    style={{
                        height: 'calc(100vh - 70px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <DynamicForm
                        formData={formData}
                        hiddenFields={props.hiddenFields}
                        submit={submit}
                        success={successProxy}
                        submitButtonTitle={submitButtonTitle}
                    />
                </div>
            </Drawer>
            {openState === undefined && (
                <>
                    {!openButton && <Button onClick={onOpenClick}>{title}</Button>}
                    {openButton && cloneElement(openButton, {onClick: onOpenClick})}
                </>
            )}
        </>
    );
};
